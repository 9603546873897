import React from 'react'
import './Footer.css'

const Footer = () => {
	return (
		<div style={{ backgroundColor: '#410009' }}>
			<footer>
				<div>
					<div className='row'>
						<div className='col text-center'>
							<h1
								className='font-weight-light text-capitalize p-3'
								style={{ color: '#f6c24e', textShadow: '2px 2px 2px #410009' }}
							>
								Screaming Eagles
							</h1>
							<h4
								className='font-weight-bold font-italic'
								style={{ color: '#f6c24e', textShadow: '2px 2px 2px #410009' }}
							>
								Est. 2015
							</h4>
							<div className='py-3'>
								<a
									href='https://www.facebook.com/CranberryScreamingEagles'
									target='_blank'
									style={{ color: '#f6c24e' }}
								>
									<i className='fab fa-facebook fa-3x mx-3'></i>
								</a>
								<a
									href='https://www.instagram.com/screamingeaglesdekhockey/'
									target='_blank'
									style={{ color: '#f6c24e' }}
								>
									<i
										className='fab fa-instagram'
										style={{ textShadow: 'black 2px 2px 2px', fontSize: '3em' }}
									></i>
								</a>
							</div>
							<p
								className='py-4 m-0 copyright'
								style={{ color: 'white', backgroundColor: '#686868' }}
							>
								A Web Application by{' '}
								<a
									href='https://www.shireystatsandstandings.com/#'
									target='_blank'
									style={{ color: 'white' }}
								>
									Shirey Stats and Standings, LLC
								</a>
							</p>
						</div>
					</div>
				</div>
			</footer>
		</div>
	)
}

export default Footer
