import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
//import './RegisterNavigation.css'
import HomeHeader from '../../shared/components/Navigation/HomeHeader'
import HomeNavLinks from '../../shared/components/Navigation/HomeNavLinks'
import SideDrawer from '../../admin/components/SideDrawer'
import Backdrop from '../../shared/components/UIElements/Backdrop'

const RostersHomeNavigation = () => {
	const [drawerIsOpen, setDrawerIsOpen] = useState(false)

	const openDrawerHandler = () => {
		setDrawerIsOpen(true)
	}

	const closeDrawerHandler = () => {
		setDrawerIsOpen(false)
	}

	return (
		<React.Fragment>
			{drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
			<SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
				<nav className='home-navigation__drawer-nav'>
					<HomeNavLinks />
				</nav>
			</SideDrawer>
			<HomeHeader>
				<button
					className='home-navigation__menu-btn'
					onClick={openDrawerHandler}
				>
					<span />
					<span />
					<span />
				</button>
				<h1 className='home-navigation__title'>TRYOUTS</h1>
				<nav className='home-navigation__header-nav'>
					<HomeNavLinks />
				</nav>
			</HomeHeader>
		</React.Fragment>
	)
}

export default RostersHomeNavigation
