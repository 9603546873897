import React, { useEffect, useState } from 'react'
import './AdminGameResults.css'
import AdminNavigation from './AdminNavigation'
import { useNavigate, useParams } from 'react-router-dom'
import Modal from '../../shared/components/UIElements/Modal'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
//import Button from '../../shared/components/UIElements/Button'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import { useHttpClient } from '../../shared/hooks/http-hook'

const AdminGameResultsChampionship = () => {
	const { sendRequest, clearError } = useHttpClient()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [homeTeamName, setHomeTeamName] = useState()
	const [homeRoster, setHomeRoster] = useState()
	const [goalies, setGoalies] = useState()
	const [goalieName, setGoalieName] = useState()
	const [goalieRosterPlayerId, setGoalieRosterPlayerId] = useState()
	const [opponent, setOpponent] = useState()
	const [rosterPlayerGameStats, setRosterPlayerGameStats] = useState()
	const [championshipGameStats, setChampionshipGameStats] = useState([])
	const [dayOfWeek, setDayOfWeek] = useState()
	const [date, setDate] = useState()
	const [time, setTime] = useState()
	const [year, setYear] = useState()
	const [teamId, setTeamId] = useState()
	const [venue, setVenue] = useState()
	const gameId = useParams().gameId
	const [rosterDropdown, setRosterDropdown] = useState([])
	const [goalieDropdown, setGoalieDropdown] = useState([])
	const [newShotsAgainst, setNewShotsAgainst] = useState()
	const [allPenaltiesFromThisGame, setAllPenaltiesFromThisGame] = useState()
	const [badGuy1, setBadGuy1] = useState()
	const [penaltyType1, setPenaltyType1] = useState()
	const [penaltyMinutes1, setPenaltyMinutes1] = useState()
	const [penaltyPeriod1, setPenaltyPeriod1] = useState()
	const [penaltyTime1, setPenaltyTime1] = useState()
	const [badGuy2, setBadGuy2] = useState()
	const [penaltyType2, setPenaltyType2] = useState()
	const [penaltyMinutes2, setPenaltyMinutes2] = useState()
	const [penaltyPeriod2, setPenaltyPeriod2] = useState()
	const [penaltyTime2, setPenaltyTime2] = useState()
	const [badGuy3, setBadGuy3] = useState()
	const [penaltyType3, setPenaltyType3] = useState()
	const [penaltyMinutes3, setPenaltyMinutes3] = useState()
	const [penaltyPeriod3, setPenaltyPeriod3] = useState()
	const [penaltyTime3, setPenaltyTime3] = useState()
	const [badGuy4, setBadGuy4] = useState()
	const [penaltyType4, setPenaltyType4] = useState()
	const [penaltyMinutes4, setPenaltyMinutes4] = useState()
	const [penaltyPeriod4, setPenaltyPeriod4] = useState()
	const [penaltyTime4, setPenaltyTime4] = useState()
	const [badGuy5, setBadGuy5] = useState()
	const [penaltyType5, setPenaltyType5] = useState()
	const [penaltyMinutes5, setPenaltyMinutes5] = useState()
	const [penaltyPeriod5, setPenaltyPeriod5] = useState()
	const [penaltyTime5, setPenaltyTime5] = useState()
	const [badGuy6, setBadGuy6] = useState()
	const [penaltyType6, setPenaltyType6] = useState()
	const [penaltyMinutes6, setPenaltyMinutes6] = useState()
	const [penaltyPeriod6, setPenaltyPeriod6] = useState()
	const [penaltyTime6, setPenaltyTime6] = useState()
	const [badGuy7, setBadGuy7] = useState()
	const [penaltyType7, setPenaltyType7] = useState()
	const [penaltyMinutes7, setPenaltyMinutes7] = useState()
	const [penaltyPeriod7, setPenaltyPeriod7] = useState()
	const [penaltyTime7, setPenaltyTime7] = useState()
	const [badGuy8, setBadGuy8] = useState()
	const [penaltyType8, setPenaltyType8] = useState()
	const [penaltyMinutes8, setPenaltyMinutes8] = useState()
	const [penaltyPeriod8, setPenaltyPeriod8] = useState()
	const [penaltyTime8, setPenaltyTime8] = useState()
	const [badGuy9, setBadGuy9] = useState()
	const [penaltyType9, setPenaltyType9] = useState()
	const [penaltyMinutes9, setPenaltyMinutes9] = useState()
	const [penaltyPeriod9, setPenaltyPeriod9] = useState()
	const [penaltyTime9, setPenaltyTime9] = useState()
	const [badGuy10, setBadGuy10] = useState()
	const [penaltyType10, setPenaltyType10] = useState()
	const [penaltyMinutes10, setPenaltyMinutes10] = useState()
	const [penaltyPeriod10, setPenaltyPeriod10] = useState()
	const [penaltyTime10, setPenaltyTime10] = useState()
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [penaltyDeleted, setPenaltyDeleted] = useState()
	const [penaltyId, setPenaltyId] = useState('')

	//Here, I want to go to the backend, using the playoffGameId, to get the
	//game date, time, venue, home team, and visitor team.
	//If stats already exist for this playoff game (like, we needed to edit the score),
	//we'll get that information as well.
	//getGameRostersAndPointsPerPeriod in admin-controller.js
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/admin/${gameId}/rostersAndPoints`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}
				setHomeRoster(responseData.homeRoster)
				setGoalies(responseData.goalies)
				setHomeTeamName(responseData.teamName)
				setOpponent(responseData.opponent)
				setRosterPlayerGameStats(responseData.rosterPlayerGameStats)
				setChampionshipGameStats(responseData.gameStats)
				setDayOfWeek(responseData.dayOfWeek)
				setDate(responseData.date)
				setTime(responseData.time)
				setYear(responseData.year)
				setTeamId(responseData.teamNameId)
				setVenue(responseData.venue)
				setAllPenaltiesFromThisGame(responseData.allPenaltiesFromThisGame)

				let rosterDropdownArray
				rosterDropdownArray = []
				if (responseData.homeRoster) {
					responseData.homeRoster.forEach((homePlayer) => {
						rosterDropdownArray.push(
							homePlayer.number +
								' - ' +
								homePlayer.firstName +
								' ' +
								homePlayer.lastName
						)
					})
					setRosterDropdown(rosterDropdownArray)
				}
				//
				//
				let goalieDropdownArray
				goalieDropdownArray = []
				if (responseData.goalies) {
					responseData.goalies.forEach((goalie) => {
						goalieDropdownArray.push(goalie.firstName + ' ' + goalie.lastName)
					})
					setGoalieDropdown(goalieDropdownArray)
				}
				//
				//
				setPenaltyDeleted(false)

				setIsLoading(false)
			} catch (err) {
				setError(err)
			}

			setIsLoading(false)
		}
		sendRequest()
	}, [gameId, penaltyDeleted])

	console.log('championshipGameStats: ' + championshipGameStats)

	let homePointsTotal,
		visitorPointsTotal,
		status,
		summary,
		score,
		prevShotsAgainst,
		prevGoalieRosterPlayerId
	if (championshipGameStats) {
		if (championshipGameStats.length > 0) {
			console.log('WE HAVE GAME STATS from this CHAMPIONSHIP game')
			console.log(
				'championshipGameStats: ' + JSON.stringify(championshipGameStats)
			)
			homePointsTotal = championshipGameStats[0].homeGoalsTotal
			visitorPointsTotal = championshipGameStats[0].visitorGoalsTotal
			prevShotsAgainst = championshipGameStats[0].shotsAgainst
			status = championshipGameStats[0].status
			summary = championshipGameStats[0].summary
			prevGoalieRosterPlayerId = championshipGameStats[0].goalieRosterPlayerId
			console.log('summary here: ' + summary)
			console.log('homeGoalsTotal here: ' + homePointsTotal)
		}
	} else {
		console.log('NO GAME STATS YET!!!')
	}
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//
	//Here, let's see if there were any penalties in this game, and we'll
	//populate the penalties if so
	let previousPenaltyPlayer1,
		previousPenaltyOffense1,
		previousPenaltyPd1,
		previousPenaltyTiming1,
		previousPim1,
		previousPenaltyPlayer2,
		previousPenaltyOffense2,
		previousPenaltyPd2,
		previousPenaltyTiming2,
		previousPim2,
		previousPenaltyPlayer3,
		previousPenaltyOffense3,
		previousPenaltyPd3,
		previousPenaltyTiming3,
		previousPim3,
		previousPenaltyPlayer4,
		previousPenaltyOffense4,
		previousPenaltyPd4,
		previousPenaltyTiming4,
		previousPim4,
		previousPenaltyPlayer5,
		previousPenaltyOffense5,
		previousPenaltyPd5,
		previousPenaltyTiming5,
		previousPim5,
		previousPenaltyPlayer6,
		previousPenaltyOffense6,
		previousPenaltyPd6,
		previousPenaltyTiming6,
		previousPim6,
		previousPenaltyPlayer7,
		previousPenaltyOffense7,
		previousPenaltyPd7,
		previousPenaltyTiming7,
		previousPim7,
		previousPenaltyPlayer8,
		previousPenaltyOffense8,
		previousPenaltyPd8,
		previousPenaltyTiming8,
		previousPim8,
		previousPenaltyPlayer9,
		previousPenaltyOffense9,
		previousPenaltyPd9,
		previousPenaltyTiming9,
		previousPim9,
		previousPenaltyPlayer10,
		previousPenaltyOffense10,
		previousPenaltyPd10,
		previousPenaltyTiming10,
		previousPim10

	/* allPenaltiesFromThisGame &&
		console.log(
			'allPenaltiesFromThisGame: ' + JSON.stringify(allPenaltiesFromThisGame)
		) */
	if (allPenaltiesFromThisGame) {
		if (allPenaltiesFromThisGame[0]) {
			previousPenaltyPlayer1 =
				/* allPenaltiesFromThisGame[0].teamName +
				' - ' + */
				allPenaltiesFromThisGame[0].jerseyNumber +
				' - ' +
				allPenaltiesFromThisGame[0].firstName +
				' ' +
				allPenaltiesFromThisGame[0].lastName
			previousPenaltyOffense1 = allPenaltiesFromThisGame[0].penaltyType
			previousPenaltyPd1 = allPenaltiesFromThisGame[0].period
			previousPenaltyTiming1 = allPenaltiesFromThisGame[0].time
			previousPim1 = allPenaltiesFromThisGame[0].minutes
		}
		//
		if (allPenaltiesFromThisGame[1]) {
			previousPenaltyPlayer2 =
				/* allPenaltiesFromThisGame[1].teamName +
				' - ' + */
				allPenaltiesFromThisGame[1].jerseyNumber +
				' - ' +
				allPenaltiesFromThisGame[1].firstName +
				' ' +
				allPenaltiesFromThisGame[1].lastName
			previousPenaltyOffense2 = allPenaltiesFromThisGame[1].penaltyType
			previousPenaltyPd2 = allPenaltiesFromThisGame[1].period
			previousPenaltyTiming2 = allPenaltiesFromThisGame[1].time
			previousPim2 = allPenaltiesFromThisGame[1].minutes
		}
		//
		if (allPenaltiesFromThisGame[2]) {
			previousPenaltyPlayer3 =
				/* allPenaltiesFromThisGame[2].teamName +
				' - ' + */
				allPenaltiesFromThisGame[2].jerseyNumber +
				' - ' +
				allPenaltiesFromThisGame[2].firstName +
				' ' +
				allPenaltiesFromThisGame[2].lastName
			previousPenaltyOffense3 = allPenaltiesFromThisGame[2].penaltyType
			previousPenaltyPd3 = allPenaltiesFromThisGame[2].period
			previousPenaltyTiming3 = allPenaltiesFromThisGame[2].time
			previousPim3 = allPenaltiesFromThisGame[2].minutes
		}
		//
		if (allPenaltiesFromThisGame[3]) {
			previousPenaltyPlayer4 =
				/* allPenaltiesFromThisGame[3].teamName +
				' - ' + */
				allPenaltiesFromThisGame[3].jerseyNumber +
				' - ' +
				allPenaltiesFromThisGame[3].firstName +
				' ' +
				allPenaltiesFromThisGame[3].lastName
			previousPenaltyOffense4 = allPenaltiesFromThisGame[3].penaltyType
			previousPenaltyPd4 = allPenaltiesFromThisGame[3].period
			previousPenaltyTiming4 = allPenaltiesFromThisGame[3].time
			previousPim4 = allPenaltiesFromThisGame[3].minutes
		}
		//
		if (allPenaltiesFromThisGame[4]) {
			previousPenaltyPlayer5 =
				/* allPenaltiesFromThisGame[4].teamName +
				' - ' + */
				allPenaltiesFromThisGame[4].jerseyNumber +
				' - ' +
				allPenaltiesFromThisGame[4].firstName +
				' ' +
				allPenaltiesFromThisGame[4].lastName
			previousPenaltyOffense5 = allPenaltiesFromThisGame[4].penaltyType
			previousPenaltyPd5 = allPenaltiesFromThisGame[4].period
			previousPenaltyTiming5 = allPenaltiesFromThisGame[4].time
			previousPim5 = allPenaltiesFromThisGame[4].minutes
		}
		//
		if (allPenaltiesFromThisGame[5]) {
			previousPenaltyPlayer6 =
				/* allPenaltiesFromThisGame[5].teamName +
				' - ' + */
				allPenaltiesFromThisGame[5].jerseyNumber +
				' - ' +
				allPenaltiesFromThisGame[5].firstName +
				' ' +
				allPenaltiesFromThisGame[5].lastName
			previousPenaltyOffense6 = allPenaltiesFromThisGame[5].penaltyType
			previousPenaltyPd6 = allPenaltiesFromThisGame[5].period
			previousPenaltyTiming6 = allPenaltiesFromThisGame[5].time
			previousPim6 = allPenaltiesFromThisGame[5].minutes
		}
		//
		if (allPenaltiesFromThisGame[6]) {
			previousPenaltyPlayer7 =
				/* allPenaltiesFromThisGame[6].teamName +
				' - ' + */
				allPenaltiesFromThisGame[6].jerseyNumber +
				' - ' +
				allPenaltiesFromThisGame[6].firstName +
				' ' +
				allPenaltiesFromThisGame[6].lastName
			previousPenaltyOffense7 = allPenaltiesFromThisGame[6].penaltyType
			previousPenaltyPd7 = allPenaltiesFromThisGame[6].period
			previousPenaltyTiming7 = allPenaltiesFromThisGame[6].time
			previousPim7 = allPenaltiesFromThisGame[6].minutes
		}
		//
		if (allPenaltiesFromThisGame[7]) {
			previousPenaltyPlayer8 =
				/* allPenaltiesFromThisGame[7].teamName +
				' - ' + */
				allPenaltiesFromThisGame[7].jerseyNumber +
				' - ' +
				allPenaltiesFromThisGame[7].firstName +
				' ' +
				allPenaltiesFromThisGame[7].lastName
			previousPenaltyOffense8 = allPenaltiesFromThisGame[7].penaltyType
			previousPenaltyPd8 = allPenaltiesFromThisGame[7].period
			previousPenaltyTiming8 = allPenaltiesFromThisGame[7].time
			previousPim8 = allPenaltiesFromThisGame[7].minutes
		}
		//
		if (allPenaltiesFromThisGame[8]) {
			previousPenaltyPlayer9 =
				/* allPenaltiesFromThisGame[8].teamName +
				' - ' + */
				allPenaltiesFromThisGame[8].jerseyNumber +
				' - ' +
				allPenaltiesFromThisGame[8].firstName +
				' ' +
				allPenaltiesFromThisGame[8].lastName
			previousPenaltyOffense9 = allPenaltiesFromThisGame[8].penaltyType
			previousPenaltyPd9 = allPenaltiesFromThisGame[8].period
			previousPenaltyTiming9 = allPenaltiesFromThisGame[8].time
			previousPim9 = allPenaltiesFromThisGame[8].minutes
		}
		//
		if (allPenaltiesFromThisGame[9]) {
			previousPenaltyPlayer10 =
				/* allPenaltiesFromThisGame[9].teamName +
				' - ' + */
				allPenaltiesFromThisGame[9].jerseyNumber +
				' - ' +
				allPenaltiesFromThisGame[9].firstName +
				' ' +
				allPenaltiesFromThisGame[9].lastName
			previousPenaltyOffense10 = allPenaltiesFromThisGame[9].penaltyType
			previousPenaltyPd10 = allPenaltiesFromThisGame[9].period
			previousPenaltyTiming10 = allPenaltiesFromThisGame[9].time
			previousPim10 = allPenaltiesFromThisGame[9].minutes
		}
	}

	//
	//
	//
	//
	let listOfPenalties
	listOfPenalties = [
		'Boarding',
		'Body',
		'Butt-Ending',
		'Charging',
		'Checking',
		'Checking from Behind',
		'Contact to the Head',
		'Cross-Check',
		'Delay of Game',
		'Elbowing',
		'Facemask',
		'Fighting',
		'High-Stick',
		'Holding',
		'Hooking',
		'Interference',
		'Kneeing',
		'Misconduct',
		'Obstruction',
		'Roughing',
		'Slashing',
		'Spearing',
		'Too Many Men',
		'Tripping/Slew Foot',
		'Unsportsmanlike Conduct',
		'Other',
	]
	//
	//
	//
	const returnGoals = (playerId) => {
		let gameGoals
		rosterPlayerGameStats.forEach((rosterPlayer) => {
			if (rosterPlayer.rosterPlayerId === playerId) {
				gameGoals = rosterPlayer.goals
			}
		})
		return gameGoals
	}
	//
	//
	//
	const returnAssists = (playerId) => {
		let gameAssists
		rosterPlayerGameStats.forEach((rosterPlayer) => {
			if (rosterPlayer.rosterPlayerId === playerId) {
				gameAssists = rosterPlayer.assists
			}
		})
		return gameAssists
	}
	//**************************************** */
	//
	//  goalie stuff
	//
	//**************************************** */
	//
	//
	let gamegoalie
	const handleGoalie = (e) => {
		console.log(e.target.value)
		gamegoalie = document.getElementById('goalie').value
		console.log('todays goalie: ' + gamegoalie)
		const split = gamegoalie.split(' ')
		const firstname = split[0]
		const lastname = split[1]
		let goalieRosterPlayerId
		goalies.forEach((goalie) => {
			if (goalie.firstName === firstname && goalie.lastName === lastname) {
				console.log('We have a match: ' + goalie.firstName)
				goalieRosterPlayerId = goalie._id
			}
		})
		console.log('goalieRosterPlayerId: ' + goalieRosterPlayerId)
		setGoalieRosterPlayerId(goalieRosterPlayerId)
		setGoalieName(gamegoalie)
	}
	//
	//
	let shotsagainst
	const handleShotsAgainst = (e) => {
		shotsagainst = document.getElementById('shotsAgainst').value
		console.log('shots against: ' + shotsagainst)
		setNewShotsAgainst(shotsagainst)
	}
	//
	//
	let prevGoalieName
	//
	if (prevGoalieRosterPlayerId && goalies) {
		goalies.forEach((goalie) => {
			if (goalie._id === prevGoalieRosterPlayerId) {
				prevGoalieName = goalie.firstName + ' ' + goalie.lastName
			}
		})
	}
	//
	//
	//
	//
	//
	//********************************************************* */
	//
	//
	//    Log penalties
	//
	//
	//********************************************************* */
	let badguy1
	const handleBadGuy1 = (e) => {
		console.log(e.target.value)
		badguy1 = document.getElementById('badGuy1').value
		console.log('badGuy1: ' + badguy1)
		setBadGuy1(badguy1)
	}

	let penaltytype1
	const handlePenaltyType1 = (e) => {
		console.log('penalty type 1: ' + e.target.value)
		penaltytype1 = e.target.value
		setPenaltyType1(penaltytype1)
	}

	let penaltyminutes1
	const handlePenaltyMinutes1 = (e) => {
		penaltyminutes1 = document.getElementById('penaltyMinutes1').value
		console.log('minutes in box: ' + penaltyminutes1)
		setPenaltyMinutes1(penaltyminutes1)
	}

	let penaltyperiod1
	const handlePenaltyPeriod1 = (e) => {
		penaltyperiod1 = e.target.value
		console.log('period of penalty 1: ' + penaltyperiod1)
		setPenaltyPeriod1(penaltyperiod1)
	}

	let penaltytime1
	const handlePenaltyTime1 = (e) => {
		penaltytime1 = e.target.value
		console.log('time of penalty: ' + penaltytime1)
		setPenaltyTime1(penaltytime1)
	}
	//**************************************************************** */
	//
	//    Penalty 2
	//
	//**************************************************************** */
	//
	let badguy2
	const handleBadGuy2 = (e) => {
		console.log(e.target.value)
		badguy2 = document.getElementById('badGuy2').value
		console.log('badGuy2: ' + badguy2)
		setBadGuy2(badguy2)
	}

	let penaltytype2
	const handlePenaltyType2 = (e) => {
		console.log('penalty type 2: ' + e.target.value)
		penaltytype2 = e.target.value
		setPenaltyType2(penaltytype2)
	}

	let penaltyminutes2
	const handlePenaltyMinutes2 = (e) => {
		penaltyminutes2 = document.getElementById('penaltyMinutes2').value
		console.log('minutes in box: ' + penaltyminutes2)
		setPenaltyMinutes2(penaltyminutes2)
	}

	let penaltyperiod2
	const handlePenaltyPeriod2 = (e) => {
		penaltyperiod2 = e.target.value
		console.log('period of penalty 2: ' + penaltyperiod2)
		setPenaltyPeriod2(penaltyperiod2)
	}

	let penaltytime2
	const handlePenaltyTime2 = (e) => {
		penaltytime2 = e.target.value
		console.log('time of penalty: ' + penaltytime2)
		setPenaltyTime2(penaltytime2)
	}
	//**************************************************************** */
	//
	//    Penalty 3
	//
	//**************************************************************** */
	//
	let badguy3
	const handleBadGuy3 = (e) => {
		console.log(e.target.value)
		badguy3 = document.getElementById('badGuy3').value
		setBadGuy3(badguy3)
	}

	let penaltytype3
	const handlePenaltyType3 = (e) => {
		penaltytype3 = e.target.value
		setPenaltyType3(penaltytype3)
	}

	let penaltyminutes3
	const handlePenaltyMinutes3 = (e) => {
		penaltyminutes3 = document.getElementById('penaltyMinutes3').value
		setPenaltyMinutes3(penaltyminutes3)
	}

	let penaltyperiod3
	const handlePenaltyPeriod3 = (e) => {
		penaltyperiod3 = e.target.value
		setPenaltyPeriod3(penaltyperiod3)
	}

	let penaltytime3
	const handlePenaltyTime3 = (e) => {
		penaltytime3 = e.target.value
		setPenaltyTime3(penaltytime3)
	}
	//**************************************************************** */
	//
	//    Penalty 4
	//
	//**************************************************************** */
	//
	let badguy4
	const handleBadGuy4 = (e) => {
		console.log(e.target.value)
		badguy4 = document.getElementById('badGuy4').value
		setBadGuy4(badguy4)
	}

	let penaltytype4
	const handlePenaltyType4 = (e) => {
		penaltytype4 = e.target.value
		setPenaltyType4(penaltytype4)
	}

	let penaltyminutes4
	const handlePenaltyMinutes4 = (e) => {
		penaltyminutes4 = document.getElementById('penaltyMinutes4').value
		setPenaltyMinutes4(penaltyminutes4)
	}

	let penaltyperiod4
	const handlePenaltyPeriod4 = (e) => {
		penaltyperiod4 = e.target.value
		setPenaltyPeriod4(penaltyperiod4)
	}

	let penaltytime4
	const handlePenaltyTime4 = (e) => {
		penaltytime4 = e.target.value
		setPenaltyTime4(penaltytime4)
	}
	//**************************************************************** */
	//
	//    Penalty 5
	//
	//**************************************************************** */
	//
	let badguy5
	const handleBadGuy5 = (e) => {
		console.log(e.target.value)
		badguy5 = document.getElementById('badGuy5').value
		setBadGuy5(badguy5)
	}

	let penaltytype5
	const handlePenaltyType5 = (e) => {
		penaltytype5 = e.target.value
		setPenaltyType5(penaltytype5)
	}

	let penaltyminutes5
	const handlePenaltyMinutes5 = (e) => {
		penaltyminutes5 = document.getElementById('penaltyMinutes5').value
		setPenaltyMinutes5(penaltyminutes5)
	}

	let penaltyperiod5
	const handlePenaltyPeriod5 = (e) => {
		penaltyperiod5 = e.target.value
		setPenaltyPeriod5(penaltyperiod5)
	}

	let penaltytime5
	const handlePenaltyTime5 = (e) => {
		penaltytime5 = e.target.value
		setPenaltyTime5(penaltytime5)
	}
	//**************************************************************** */
	//
	//    Penalty 6
	//
	//**************************************************************** */
	//
	let badguy6
	const handleBadGuy6 = (e) => {
		console.log(e.target.value)
		badguy6 = document.getElementById('badGuy6').value
		setBadGuy6(badguy6)
	}

	let penaltytype6
	const handlePenaltyType6 = (e) => {
		penaltytype6 = e.target.value
		setPenaltyType6(penaltytype6)
	}

	let penaltyminutes6
	const handlePenaltyMinutes6 = (e) => {
		penaltyminutes6 = document.getElementById('penaltyMinutes6').value
		setPenaltyMinutes6(penaltyminutes6)
	}

	let penaltyperiod6
	const handlePenaltyPeriod6 = (e) => {
		penaltyperiod6 = e.target.value
		setPenaltyPeriod6(penaltyperiod6)
	}

	let penaltytime6
	const handlePenaltyTime6 = (e) => {
		penaltytime6 = e.target.value
		setPenaltyTime6(penaltytime6)
	}
	//**************************************************************** */
	//
	//    Penalty 7
	//
	//**************************************************************** */
	//
	let badguy7
	const handleBadGuy7 = (e) => {
		console.log(e.target.value)
		badguy7 = document.getElementById('badGuy7').value
		setBadGuy7(badguy7)
	}

	let penaltytype7
	const handlePenaltyType7 = (e) => {
		penaltytype7 = e.target.value
		setPenaltyType7(penaltytype7)
	}

	let penaltyminutes7
	const handlePenaltyMinutes7 = (e) => {
		penaltyminutes7 = document.getElementById('penaltyMinutes7').value
		setPenaltyMinutes7(penaltyminutes7)
	}

	let penaltyperiod7
	const handlePenaltyPeriod7 = (e) => {
		penaltyperiod7 = e.target.value
		setPenaltyPeriod7(penaltyperiod7)
	}

	let penaltytime7
	const handlePenaltyTime7 = (e) => {
		penaltytime7 = e.target.value
		setPenaltyTime7(penaltytime7)
	}
	//**************************************************************** */
	//
	//    Penalty 8
	//
	//**************************************************************** */
	//
	let badguy8
	const handleBadGuy8 = (e) => {
		console.log(e.target.value)
		badguy8 = document.getElementById('badGuy8').value
		setBadGuy8(badguy8)
	}

	let penaltytype8
	const handlePenaltyType8 = (e) => {
		penaltytype8 = e.target.value
		setPenaltyType8(penaltytype8)
	}

	let penaltyminutes8
	const handlePenaltyMinutes8 = (e) => {
		penaltyminutes8 = document.getElementById('penaltyMinutes8').value
		setPenaltyMinutes8(penaltyminutes8)
	}

	let penaltyperiod8
	const handlePenaltyPeriod8 = (e) => {
		penaltyperiod8 = e.target.value
		setPenaltyPeriod8(penaltyperiod8)
	}

	let penaltytime8
	const handlePenaltyTime8 = (e) => {
		penaltytime8 = e.target.value
		setPenaltyTime8(penaltytime8)
	}
	//**************************************************************** */
	//
	//    Penalty 9
	//
	//**************************************************************** */
	//
	let badguy9
	const handleBadGuy9 = (e) => {
		console.log(e.target.value)
		badguy9 = document.getElementById('badGuy9').value
		setBadGuy9(badguy9)
	}

	let penaltytype9
	const handlePenaltyType9 = (e) => {
		penaltytype9 = e.target.value
		setPenaltyType9(penaltytype9)
	}

	let penaltyminutes9
	const handlePenaltyMinutes9 = (e) => {
		penaltyminutes9 = document.getElementById('penaltyMinutes9').value
		setPenaltyMinutes9(penaltyminutes9)
	}

	let penaltyperiod9
	const handlePenaltyPeriod9 = (e) => {
		penaltyperiod9 = e.target.value
		setPenaltyPeriod9(penaltyperiod9)
	}

	let penaltytime9
	const handlePenaltyTime9 = (e) => {
		penaltytime9 = e.target.value
		setPenaltyTime9(penaltytime9)
	}
	//**************************************************************** */
	//
	//    Penalty 10
	//
	//**************************************************************** */
	//
	let badguy10
	const handleBadGuy10 = (e) => {
		console.log(e.target.value)
		badguy10 = document.getElementById('badGuy10').value
		setBadGuy10(badguy10)
	}

	let penaltytype10
	const handlePenaltyType10 = (e) => {
		penaltytype10 = e.target.value
		setPenaltyType10(penaltytype10)
	}

	let penaltyminutes10
	const handlePenaltyMinutes10 = (e) => {
		penaltyminutes10 = document.getElementById('penaltyMinutes10').value
		setPenaltyMinutes10(penaltyminutes10)
	}

	let penaltyperiod10
	const handlePenaltyPeriod10 = (e) => {
		penaltyperiod10 = e.target.value
		setPenaltyPeriod10(penaltyperiod10)
	}

	let penaltytime10
	const handlePenaltyTime10 = (e) => {
		penaltytime10 = e.target.value
		setPenaltyTime10(penaltytime10)
	}
	//
	//
	let navigate = useNavigate()
	//Here, I want to tap into the backend and update stats for the game and rosterPlayers
	//Maybe...or maybe I want to do it another way...
	const logChampionshipGameStats = async () => {
		if (!goalieName && !prevGoalieName) {
			alert('Please select a Goalie!')
		} else {
			const gameSummary = document.getElementById('gameSummary').value
			const homePointsTotal = document.getElementById('homeGoalsTotal').value
			const visitorPointsTotal =
				document.getElementById('visitorGoalsTotal').value

			console.log('inside logGameStats')
			console.log('old shots against: ' + prevShotsAgainst)
			console.log('new shots against: ' + newShotsAgainst)

			let shotsAgainst = newShotsAgainst || prevShotsAgainst
			//**************************************************** */
			//
			//  Individual Player stats:
			//
			//***************************************************** */
			if (homePointsTotal == visitorPointsTotal) {
				alert('You cant have a TIE in a Championship game!')
			} else {
				let homeStats
				homeStats = []
				//
				homeRoster.forEach((rosterPlayer) => {
					homeStats.push(
						rosterPlayer._id +
							'|' +
							document.getElementById('goals' + rosterPlayer._id).value +
							'|' +
							document.getElementById('assists' + rosterPlayer._id).value
					)
				})

				try {
					//tapping into backend to add championship game stats...
					// admin-controller    createChampionshipGameStats
					setIsLoading(true)
					const response = await fetch(
						`${process.env.REACT_APP_BACKEND_URL}/admin/championshipGameStats/${gameId}`,
						{
							method: 'PATCH',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({
								homeStats,
								homePointsTotal,
								visitorPointsTotal,
								shotsAgainst,
								gameSummary,
								goalieName,
								goalieRosterPlayerId,
								gameId,
								badGuy1,
								year,
								teamId,
								penaltyType1,
								penaltyMinutes1,
								penaltyPeriod1,
								penaltyTime1,
								badGuy2,
								penaltyType2,
								penaltyMinutes2,
								penaltyPeriod2,
								penaltyTime2,
								badGuy3,
								penaltyType3,
								penaltyMinutes3,
								penaltyPeriod3,
								penaltyTime3,
								badGuy4,
								penaltyType4,
								penaltyMinutes4,
								penaltyPeriod4,
								penaltyTime4,
								badGuy5,
								penaltyType5,
								penaltyMinutes5,
								penaltyPeriod5,
								penaltyTime5,
								badGuy6,
								penaltyType6,
								penaltyMinutes6,
								penaltyPeriod6,
								penaltyTime6,
								badGuy7,
								penaltyType7,
								penaltyMinutes7,
								penaltyPeriod7,
								penaltyTime7,
								badGuy8,
								penaltyType8,
								penaltyMinutes8,
								penaltyPeriod8,
								penaltyTime8,
								badGuy9,
								penaltyType9,
								penaltyMinutes9,
								penaltyPeriod9,
								penaltyTime9,
								badGuy10,
								penaltyType10,
								penaltyMinutes10,
								penaltyPeriod10,
								penaltyTime10,
								previousPenaltyPlayer1,
								previousPenaltyOffense1,
								previousPenaltyPd1,
								previousPenaltyTiming1,
								previousPim1,
								previousPenaltyPlayer2,
								previousPenaltyOffense2,
								previousPenaltyPd2,
								previousPenaltyTiming2,
								previousPim2,
								previousPenaltyPlayer3,
								previousPenaltyOffense3,
								previousPenaltyPd3,
								previousPenaltyTiming3,
								previousPim3,
								previousPenaltyPlayer4,
								previousPenaltyOffense4,
								previousPenaltyPd4,
								previousPenaltyTiming4,
								previousPim4,
								previousPenaltyPlayer5,
								previousPenaltyOffense5,
								previousPenaltyPd5,
								previousPenaltyTiming5,
								previousPim5,
								previousPenaltyPlayer6,
								previousPenaltyOffense6,
								previousPenaltyPd6,
								previousPenaltyTiming6,
								previousPim6,
								previousPenaltyPlayer7,
								previousPenaltyOffense7,
								previousPenaltyPd7,
								previousPenaltyTiming7,
								previousPim7,
								previousPenaltyPlayer8,
								previousPenaltyOffense8,
								previousPenaltyPd8,
								previousPenaltyTiming8,
								previousPim8,
								previousPenaltyPlayer9,
								previousPenaltyOffense9,
								previousPenaltyPd9,
								previousPenaltyTiming9,
								previousPim9,
								previousPenaltyPlayer10,
								previousPenaltyOffense10,
								previousPenaltyPd10,
								previousPenaltyTiming10,
								previousPim10,
							}),
						}
					)
					const responseData = await response.json()
					if (!response.ok) {
						throw new Error(responseData.message)
					}
					setIsLoading(false)
					navigate('/admin/masterSchedule')
				} catch (err) {
					console.log('error: ' + err)
					setIsLoading(false)
					setError(
						err.message ||
							'Something went wrong with adding championship game stats'
					)
				}
			}
		}
	}
	//
	//
	//
	//
	// Modal for deleting penalties...
	const showDeleteWarningHandler = (e) => {
		const value = e.target.id
		console.log('value: ' + value)
		setPenaltyId(value)
		setShowConfirmModal(true)
	}
	//
	//
	const cancelDeleteHandler = () => {
		setShowConfirmModal(false)
	}
	//
	//
	const confirmDeleteHandler = async () => {
		setShowConfirmModal(false)
		//console.log('itemId: ' + itemId)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${penaltyId}/deletePenalty`,
				'DELETE'
			)
		} catch (err) {
			console.log(err)
		}
		setPenaltyDeleted(true)
		console.log('DELETING...')
	}
	//
	//
	//
	//
	//
	return (
		<React.Fragment>
			<AdminNavigation />
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			<Modal
				show={showConfirmModal}
				onCancel={cancelDeleteHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to delete this penalty?
						</p>
						<br></br>
						<button
							className='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelDeleteHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							style={{ color: 'red' }}
							danger
							onClick={confirmDeleteHandler}
						>
							DELETE PENALTY
						</button>
					</React.Fragment>
				}
			></Modal>
			<span>
				<h1 style={{ marginTop: '5rem' }}>
					<h1 style={{ color: 'red', fontWeight: 'bold' }}>CHAMPIONSHIP!</h1>{' '}
					{homeTeamName} vs. {opponent}
				</h1>
				<p style={{ textAlign: 'center' }}>
					{dayOfWeek},{' '}
					{date && date.substr(0, 2) === '01' && 'Jan. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '02' && 'Feb. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '03' && 'Mar. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '04' && 'Apr. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '05' && 'May ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '06' && 'June ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '07' && 'July ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '08' && 'Aug. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '09' && 'Sept. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '10' && 'Oct. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '11' && 'Nov. ' + date.substr(3, 2)}
					{date &&
						date.substr(0, 2) === '12' &&
						'Dec. ' + date.substr(3, 2)},{' '}
					{time &&
						time.substr(0, 2) === '01' &&
						'1:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '02' &&
						'2:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '03' &&
						'3:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '04' &&
						'4:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '05' &&
						'5:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '06' &&
						'6:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '07' &&
						'7:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '08' &&
						'8:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '09' &&
						'9:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '10' &&
						'10:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '11' &&
						'11:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '12' &&
						'12:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '13' &&
						'1:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '14' &&
						'2:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '15' &&
						'3:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '16' &&
						'4:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '17' &&
						'5:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '18' &&
						'6:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '19' &&
						'7:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '20' &&
						'8:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '21' &&
						'9:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '22' &&
						'10:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '23' &&
						'11:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '24' &&
						'12:' + time.substr(3, 2) + ' am'}
					, {venue}
				</p>
			</span>
			<div className='row'>
				<div className='col-sm'></div>
				<div className='col-sm'></div>
			</div>

			<div className='row' style={{ paddingBottom: '2rem' }}>
				{/* <div className='col-sm'></div> */}
				<div className='col-sm gameSummary'>
					{/* <label for='textarea'>GAME SUMMARY:</label> */}
					<textarea
						className='summaryTextArea'
						id='gameSummary'
						defaultValue={summary}
						placeholder='Good game?  Write something about it...'
						rows='2'
						cols='35'
					></textarea>
				</div>
				{/* <div className='col-sm'></div> */}
			</div>

			<table className='AdminRoster' id='adminPlayoffRoster'>
				<thead>
					<tr>
						<th>Team</th>
						<th>Final</th>
					</tr>
				</thead>
				<tbody>
					<tr style={{ height: '5px' }}>
						<td className='playoffTeamName'>{homeTeamName}</td>
						<td>
							<input
								id='homeGoalsTotal'
								type='number'
								min='0'
								max='99'
								defaultValue={homePointsTotal}
							/>
						</td>
					</tr>
					<tr>
						<td className='playoffTeamName'>{opponent}</td>
						<td>
							<input
								id='visitorGoalsTotal'
								type='number'
								min='0'
								max='99'
								defaultValue={visitorPointsTotal}
							/>
						</td>
					</tr>
				</tbody>
			</table>
			<div className='row'>
				<div className='col' /* style={{ marginLeft: '5%' }} */>
					{!isLoading && homeRoster && (
						<div className='AdminRoster2'>
							<h1>{homeTeamName} - Player Stats</h1>
							<table id='adminRoster'>
								<thead>
									<tr>
										<th>#</th>
										<th>Player</th>
										<th>Goals</th>
										<th>Assists</th>
									</tr>
								</thead>
								<tbody>
									{homeRoster.map((player, key) => {
										return (
											<tr key={key}>
												{/* <td>{player.number}</td> */}
												<td>{player.number}</td>
												<td>
													{player.firstName}{' '}
													{player.middleInitial && player.middleInitial}{' '}
													{player.lastName}
												</td>
												<td>
													<input
														id={'goals' + player._id}
														type='number'
														defaultValue={returnGoals(player._id)}
														min='0'
														max='99'
													/>
												</td>
												<td>
													<input
														id={'assists' + player._id}
														type='number'
														defaultValue={returnAssists(player._id)}
														min='0'
														max='99'
													/>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
							<h1 style={{ marginTop: '4rem' }}>Goalie Stats</h1>
							<div className='row'>
								<div className='col-sm'></div>
								<div className='col-sm gameStatus'>
									GOALIE:
									<select
										required
										id='goalie'
										className='listOfPenalties dropdownElement'
										onChange={handleGoalie}
									>
										<option defaultValue>{prevGoalieName}</option>
										{goalieDropdown.map((goalie, i) => (
											<option value={goalie} key={i}>
												{goalie.toUpperCase()}
											</option>
										))}
										<option>Someone else</option>
									</select>
								</div>
								<div className='col-sm'></div>
							</div>
							<h5 style={{ textAlign: 'center' }}>
								"Shots against" will be used to determine Save Percentage
							</h5>
							<div className='col-sm gameSummary'>
								<label for='textarea'>SHOTS AGAINST:</label>
								<input
									id='shotsAgainst'
									type='number'
									min='0'
									max='99'
									defaultValue={prevShotsAgainst}
									onChange={handleShotsAgainst}
								></input>
							</div>
						</div>
					)}
				</div>
			</div>
			<div style={{ marginTop: '3rem' }}>
				<h3 style={{ textAlign: 'center' }}>Penalties</h3>
				<table
					className='AdminRoster'
					id='adminRoster'
					style={{
						width: 'auto',
						marginLeft: 'auto',
						marginRight: 'auto',
						marginTop: '0rem',
						marginBottom: '0rem',
					}}
				>
					<thead>
						<tr>
							<th style={{ textAlign: 'left' }}>Player</th>
							<th style={{ textAlign: 'left' }}>Penalty</th>
							<th style={{ textAlign: 'left' }}>Period</th>
							<th style={{ textAlign: 'left' }}>Time</th>
							<th>Minutes</th>
							{allPenaltiesFromThisGame &&
								allPenaltiesFromThisGame.length > 0 && <th></th>}
						</tr>
					</thead>
					{rosterDropdown && (
						<tbody>
							<tr style={{ height: '5px' }}>
								<td style={{ textAlign: 'left' }}>
									<select
										id='badGuy1'
										onChange={handleBadGuy1}
										//value={selectedValue1}
										className='penaltyPlayerDropdown dropdownElement'
									>
										<option defaultValue>{previousPenaltyPlayer1}</option>
										{rosterDropdown.map((player, i) => (
											<option value={player} key={i}>
												{player}
											</option>
										))}
									</select>
								</td>

								<td style={{ textAlign: 'left' }}>
									<select
										className='listOfPenalties dropdownElement'
										onChange={handlePenaltyType1}
									>
										<option defaultValue>{previousPenaltyOffense1}</option>
										{listOfPenalties.map((penalty, i) => (
											<option value={penalty} key={i}>
												{penalty.toUpperCase()}
											</option>
										))}
									</select>
								</td>
								<td>
									<input
										id='penaltyPeriod1'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyPeriod1}
										defaultValue={previousPenaltyPd1}
									/>
								</td>
								<td>
									<input
										id='penaltyTime1'
										className='inputElement'
										type='text'
										onChange={handlePenaltyTime1}
										defaultValue={previousPenaltyTiming1}
									/>
								</td>
								<td>
									<input
										id='penaltyMinutes1'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyMinutes1}
										defaultValue={previousPim1}
									/>
								</td>
								{allPenaltiesFromThisGame && allPenaltiesFromThisGame[0] && (
									<td>
										<button
											className='buttonButton'
											style={{ color: 'red' }}
											id={allPenaltiesFromThisGame[0]._id}
											onClick={(e) => showDeleteWarningHandler(e)}
										>
											DELETE
										</button>
									</td>
								)}
							</tr>
							{/* //
							//           ------- Penalty 2 --------
							// */}
							<tr style={{ height: '5px' }}>
								<td style={{ textAlign: 'left' }}>
									<select
										id='badGuy2'
										onChange={handleBadGuy2}
										//value={selectedValue1}
										className='penaltyPlayerDropdown dropdownElement'
									>
										<option defaultValue>{previousPenaltyPlayer2}</option>
										{rosterDropdown.map((player, i) => (
											<option value={player} key={i}>
												{player}
											</option>
										))}
									</select>
								</td>

								<td style={{ textAlign: 'left' }}>
									<select
										className='listOfPenalties dropdownElement'
										onChange={handlePenaltyType2}
									>
										<option defaultValue>{previousPenaltyOffense2}</option>
										{listOfPenalties.map((penalty, i) => (
											<option value={penalty} key={i}>
												{penalty.toUpperCase()}
											</option>
										))}
									</select>
								</td>
								<td>
									<input
										id='penaltyPeriod2'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyPeriod2}
										defaultValue={previousPenaltyPd2}
									/>
								</td>
								<td>
									<input
										id='penaltyTime2'
										className='inputElement'
										type='text'
										onChange={handlePenaltyTime2}
										defaultValue={previousPenaltyTiming2}
									/>
								</td>
								<td>
									<input
										id='penaltyMinutes2'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyMinutes2}
										defaultValue={previousPim2}
									/>
								</td>
								{allPenaltiesFromThisGame && allPenaltiesFromThisGame[1] && (
									<td>
										<button
											className='buttonButton'
											style={{ color: 'red' }}
											id={allPenaltiesFromThisGame[1]._id}
											onClick={(e) => showDeleteWarningHandler(e)}
										>
											DELETE
										</button>
									</td>
								)}
							</tr>
							{/* //
							//           ------- Penalty 3 --------
							// */}
							<tr style={{ height: '5px' }}>
								<td style={{ textAlign: 'left' }}>
									<select
										id='badGuy3'
										onChange={handleBadGuy3}
										//value={selectedValue1}
										className='penaltyPlayerDropdown dropdownElement'
									>
										<option defaultValue>{previousPenaltyPlayer3}</option>
										{rosterDropdown.map((player, i) => (
											<option value={player} key={i}>
												{player}
											</option>
										))}
									</select>
								</td>

								<td style={{ textAlign: 'left' }}>
									<select
										className='listOfPenalties dropdownElement'
										onChange={handlePenaltyType3}
									>
										<option defaultValue>{previousPenaltyOffense3}</option>
										{listOfPenalties.map((penalty, i) => (
											<option value={penalty} key={i}>
												{penalty.toUpperCase()}
											</option>
										))}
									</select>
								</td>
								<td>
									<input
										id='penaltyPeriod3'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyPeriod3}
										defaultValue={previousPenaltyPd3}
									/>
								</td>
								<td>
									<input
										id='penaltyTime3'
										className='inputElement'
										type='text'
										onChange={handlePenaltyTime3}
										defaultValue={previousPenaltyTiming3}
									/>
								</td>
								<td>
									<input
										id='penaltyMinutes3'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyMinutes3}
										defaultValue={previousPim3}
									/>
								</td>
								{allPenaltiesFromThisGame && allPenaltiesFromThisGame[2] && (
									<td>
										<button
											className='buttonButton'
											style={{ color: 'red' }}
											id={allPenaltiesFromThisGame[2]._id}
											onClick={(e) => showDeleteWarningHandler(e)}
										>
											DELETE
										</button>
									</td>
								)}
							</tr>
							{/* //
							//           ------- Penalty 4 --------
							// */}
							<tr style={{ height: '5px' }}>
								<td style={{ textAlign: 'left' }}>
									<select
										id='badGuy4'
										onChange={handleBadGuy4}
										//value={selectedValue1}
										className='penaltyPlayerDropdown dropdownElement'
									>
										<option defaultValue>{previousPenaltyPlayer4}</option>
										{rosterDropdown.map((player, i) => (
											<option value={player} key={i}>
												{player}
											</option>
										))}
									</select>
								</td>

								<td style={{ textAlign: 'left' }}>
									<select
										className='listOfPenalties dropdownElement'
										onChange={handlePenaltyType4}
									>
										<option defaultValue>{previousPenaltyOffense4}</option>
										{listOfPenalties.map((penalty, i) => (
											<option value={penalty} key={i}>
												{penalty.toUpperCase()}
											</option>
										))}
									</select>
								</td>
								<td>
									<input
										id='penaltyPeriod4'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyPeriod4}
										defaultValue={previousPenaltyPd4}
									/>
								</td>
								<td>
									<input
										id='penaltyTime4'
										className='inputElement'
										type='text'
										onChange={handlePenaltyTime4}
										defaultValue={previousPenaltyTiming4}
									/>
								</td>
								<td>
									<input
										id='penaltyMinutes4'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyMinutes4}
										defaultValue={previousPim4}
									/>
								</td>
								{allPenaltiesFromThisGame && allPenaltiesFromThisGame[3] && (
									<td>
										<button
											className='buttonButton'
											style={{ color: 'red' }}
											id={allPenaltiesFromThisGame[3]._id}
											onClick={(e) => showDeleteWarningHandler(e)}
										>
											DELETE
										</button>
									</td>
								)}
							</tr>
							{/* //
							//           ------- Penalty 5 --------
							// */}
							<tr style={{ height: '5px' }}>
								<td style={{ textAlign: 'left' }}>
									<select
										id='badGuy5'
										onChange={handleBadGuy5}
										//value={selectedValue1}
										className='penaltyPlayerDropdown dropdownElement'
									>
										<option defaultValue>{previousPenaltyPlayer5}</option>
										{rosterDropdown.map((player, i) => (
											<option value={player} key={i}>
												{player}
											</option>
										))}
									</select>
								</td>

								<td style={{ textAlign: 'left' }}>
									<select
										className='listOfPenalties dropdownElement'
										onChange={handlePenaltyType5}
									>
										<option defaultValue>{previousPenaltyOffense5}</option>
										{listOfPenalties.map((penalty, i) => (
											<option value={penalty} key={i}>
												{penalty.toUpperCase()}
											</option>
										))}
									</select>
								</td>
								<td>
									<input
										id='penaltyPeriod5'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyPeriod5}
										defaultValue={previousPenaltyPd5}
									/>
								</td>
								<td>
									<input
										id='penaltyTime5'
										className='inputElement'
										type='text'
										onChange={handlePenaltyTime5}
										defaultValue={previousPenaltyTiming5}
									/>
								</td>
								<td>
									<input
										id='penaltyMinutes5'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyMinutes5}
										defaultValue={previousPim5}
									/>
								</td>
								{allPenaltiesFromThisGame && allPenaltiesFromThisGame[4] && (
									<td>
										<button
											className='buttonButton'
											style={{ color: 'red' }}
											id={allPenaltiesFromThisGame[4]._id}
											onClick={(e) => showDeleteWarningHandler(e)}
										>
											DELETE
										</button>
									</td>
								)}
							</tr>
							{/* //
							//           ------- Penalty 6 --------
							// */}
							<tr style={{ height: '5px' }}>
								<td style={{ textAlign: 'left' }}>
									<select
										id='badGuy6'
										onChange={handleBadGuy6}
										//value={selectedValue1}
										className='penaltyPlayerDropdown dropdownElement'
									>
										<option defaultValue>{previousPenaltyPlayer6}</option>
										{rosterDropdown.map((player, i) => (
											<option value={player} key={i}>
												{player}
											</option>
										))}
									</select>
								</td>

								<td style={{ textAlign: 'left' }}>
									<select
										className='listOfPenalties dropdownElement'
										onChange={handlePenaltyType6}
									>
										<option defaultValue>{previousPenaltyOffense6}</option>
										{listOfPenalties.map((penalty, i) => (
											<option value={penalty} key={i}>
												{penalty.toUpperCase()}
											</option>
										))}
									</select>
								</td>
								<td>
									<input
										id='penaltyPeriod6'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyPeriod6}
										defaultValue={previousPenaltyPd6}
									/>
								</td>
								<td>
									<input
										id='penaltyTime6'
										className='inputElement'
										type='text'
										onChange={handlePenaltyTime6}
										defaultValue={previousPenaltyTiming6}
									/>
								</td>
								<td>
									<input
										id='penaltyMinutes6'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyMinutes6}
										defaultValue={previousPim6}
									/>
								</td>
								{allPenaltiesFromThisGame && allPenaltiesFromThisGame[5] && (
									<td>
										<button
											className='buttonButton'
											style={{ color: 'red' }}
											id={allPenaltiesFromThisGame[5]._id}
											onClick={(e) => showDeleteWarningHandler(e)}
										>
											DELETE
										</button>
									</td>
								)}
							</tr>
							{/* //
							//           ------- Penalty 7 --------
							// */}
							<tr style={{ height: '5px' }}>
								<td style={{ textAlign: 'left' }}>
									<select
										id='badGuy7'
										onChange={handleBadGuy7}
										//value={selectedValue1}
										className='penaltyPlayerDropdown dropdownElement'
									>
										<option defaultValue>{previousPenaltyPlayer7}</option>
										{rosterDropdown.map((player, i) => (
											<option value={player} key={i}>
												{player}
											</option>
										))}
									</select>
								</td>

								<td style={{ textAlign: 'left' }}>
									<select
										className='listOfPenalties dropdownElement'
										onChange={handlePenaltyType7}
									>
										<option defaultValue>{previousPenaltyOffense7}</option>
										{listOfPenalties.map((penalty, i) => (
											<option value={penalty} key={i}>
												{penalty.toUpperCase()}
											</option>
										))}
									</select>
								</td>
								<td>
									<input
										id='penaltyPeriod7'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyPeriod7}
										defaultValue={previousPenaltyPd7}
									/>
								</td>
								<td>
									<input
										id='penaltyTime7'
										className='inputElement'
										type='text'
										onChange={handlePenaltyTime7}
										defaultValue={previousPenaltyTiming7}
									/>
								</td>
								<td>
									<input
										id='penaltyMinutes7'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyMinutes7}
										defaultValue={previousPim7}
									/>
								</td>
								{allPenaltiesFromThisGame && allPenaltiesFromThisGame[6] && (
									<td>
										<button
											className='buttonButton'
											style={{ color: 'red' }}
											id={allPenaltiesFromThisGame[6]._id}
											onClick={(e) => showDeleteWarningHandler(e)}
										>
											DELETE
										</button>
									</td>
								)}
							</tr>
							{/* //
							//           ------- Penalty 8 --------
							// */}
							<tr style={{ height: '5px' }}>
								<td style={{ textAlign: 'left' }}>
									<select
										id='badGuy8'
										onChange={handleBadGuy8}
										//value={selectedValue1}
										className='penaltyPlayerDropdown dropdownElement'
									>
										<option defaultValue>{previousPenaltyPlayer8}</option>
										{rosterDropdown.map((player, i) => (
											<option value={player} key={i}>
												{player}
											</option>
										))}
									</select>
								</td>

								<td style={{ textAlign: 'left' }}>
									<select
										className='listOfPenalties dropdownElement'
										onChange={handlePenaltyType8}
									>
										<option defaultValue>{previousPenaltyOffense8}</option>
										{listOfPenalties.map((penalty, i) => (
											<option value={penalty} key={i}>
												{penalty.toUpperCase()}
											</option>
										))}
									</select>
								</td>
								<td>
									<input
										id='penaltyPeriod8'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyPeriod8}
										defaultValue={previousPenaltyPd8}
									/>
								</td>
								<td>
									<input
										id='penaltyTime8'
										className='inputElement'
										type='text'
										onChange={handlePenaltyTime8}
										defaultValue={previousPenaltyTiming8}
									/>
								</td>
								<td>
									<input
										id='penaltyMinutes8'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyMinutes8}
										defaultValue={previousPim8}
									/>
								</td>
								{allPenaltiesFromThisGame && allPenaltiesFromThisGame[7] && (
									<td>
										<button
											className='buttonButton'
											style={{ color: 'red' }}
											id={allPenaltiesFromThisGame[7]._id}
											onClick={(e) => showDeleteWarningHandler(e)}
										>
											DELETE
										</button>
									</td>
								)}
							</tr>
							{/* //
							//           ------- Penalty 9 --------
							// */}
							<tr style={{ height: '5px' }}>
								<td style={{ textAlign: 'left' }}>
									<select
										id='badGuy9'
										onChange={handleBadGuy9}
										//value={selectedValue1}
										className='penaltyPlayerDropdown dropdownElement'
									>
										<option defaultValue>{previousPenaltyPlayer9}</option>
										{rosterDropdown.map((player, i) => (
											<option value={player} key={i}>
												{player}
											</option>
										))}
									</select>
								</td>

								<td style={{ textAlign: 'left' }}>
									<select
										className='listOfPenalties dropdownElement'
										onChange={handlePenaltyType9}
									>
										<option defaultValue>{previousPenaltyOffense9}</option>
										{listOfPenalties.map((penalty, i) => (
											<option value={penalty} key={i}>
												{penalty.toUpperCase()}
											</option>
										))}
									</select>
								</td>
								<td>
									<input
										id='penaltyPeriod9'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyPeriod9}
										defaultValue={previousPenaltyPd9}
									/>
								</td>
								<td>
									<input
										id='penaltyTime9'
										className='inputElement'
										type='text'
										onChange={handlePenaltyTime9}
										defaultValue={previousPenaltyTiming9}
									/>
								</td>
								<td>
									<input
										id='penaltyMinutes9'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyMinutes9}
										defaultValue={previousPim9}
									/>
								</td>
								{allPenaltiesFromThisGame && allPenaltiesFromThisGame[8] && (
									<td>
										<button
											className='buttonButton'
											style={{ color: 'red' }}
											id={allPenaltiesFromThisGame[8]._id}
											onClick={(e) => showDeleteWarningHandler(e)}
										>
											DELETE
										</button>
									</td>
								)}
							</tr>
							{/* //
							//           ------- Penalty 10 --------
							// */}
							<tr style={{ height: '5px' }}>
								<td style={{ textAlign: 'left' }}>
									<select
										id='badGuy10'
										onChange={handleBadGuy10}
										//value={selectedValue1}
										className='penaltyPlayerDropdown dropdownElement'
									>
										<option defaultValue>{previousPenaltyPlayer10}</option>
										{rosterDropdown.map((player, i) => (
											<option value={player} key={i}>
												{player}
											</option>
										))}
									</select>
								</td>

								<td style={{ textAlign: 'left' }}>
									<select
										className='listOfPenalties dropdownElement'
										onChange={handlePenaltyType10}
									>
										<option defaultValue>{previousPenaltyOffense10}</option>
										{listOfPenalties.map((penalty, i) => (
											<option value={penalty} key={i}>
												{penalty.toUpperCase()}
											</option>
										))}
									</select>
								</td>
								<td>
									<input
										id='penaltyPeriod10'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyPeriod10}
										defaultValue={previousPenaltyPd10}
									/>
								</td>
								<td>
									<input
										id='penaltyTime10'
										className='inputElement'
										type='text'
										onChange={handlePenaltyTime10}
										defaultValue={previousPenaltyTiming10}
									/>
								</td>
								<td>
									<input
										id='penaltyMinutes10'
										className='inputElement'
										type='number'
										min='0'
										max='99'
										onChange={handlePenaltyMinutes10}
										defaultValue={previousPim10}
									/>
								</td>
								{allPenaltiesFromThisGame && allPenaltiesFromThisGame[9] && (
									<td>
										<button
											className='buttonButton'
											style={{ color: 'red' }}
											id={allPenaltiesFromThisGame[9]._id}
											onClick={(e) => showDeleteWarningHandler(e)}
										>
											DELETE
										</button>
									</td>
								)}
							</tr>
						</tbody>
					)}
				</table>
			</div>
			<div
				className='col-sm'
				style={{
					// width: '7%',
					marginLeft: 'auto',
					marginRight: 'auto',
					display: 'block',
					paddingTop: '3%',
					marginBottom: '3%',
					textAlign: 'center',
				}}
			>
				<AdminButton onClick={logChampionshipGameStats}>SAVE</AdminButton>
			</div>
		</React.Fragment>
	)
}

export default AdminGameResultsChampionship
