import React, { useRef, useState, useEffect } from 'react'

import Button from '../UIElements/Button'
import './ImageUpload.css'

const ImageUpload = (props) => {
	//console.log('props: ' + JSON.stringify(props))
	const [file, setFile] = useState()
	const [previewUrl, setPreviewUrl] = useState()
	const [isValid, setIsValid] = useState(false)

	const filePickerRef = useRef()

	//This useEffect function will generate a preview of the image.
	//This function should trigger whenever the file changes, so we
	//pass 'file' as a dependency.
	//FileReader is an API that's built into the browser.  We can use
	//the fileReader to convert a file (which is like binary data) into
	//a readable or outputtable image.
	useEffect(() => {
		if (!file) {
			return
		}
		const fileReader = new FileReader()
		fileReader.onload = () => {
			setPreviewUrl(fileReader.result)
		}
		fileReader.readAsDataURL(file)
	}, [file])

	//Obviously, we get an event object (the click)
	//The goal in pickedHandler is to generate something that helps
	//us preview the pic, AND also forward the picture to the surrounding
	//component where we use the image upload component in (Auth.js or NewPlace.js)
	//event.target.files  This is default javascript.  This event target will hold
	//the file(s) that the user selected, (that is of course, if the event target
	//file picker, which this one is (type="file")).
	const pickedHandler = (event) => {
		console.log('pickedHandler event: ' + event.target.files[0])
		console.dir(event.target.files[0])
		let pickedFile
		let fileIsValid = isValid

		if (event.target.files && event.target.files.length === 1) {
			pickedFile = event.target.files[0]
			setFile(pickedFile)
			setIsValid(true)
			fileIsValid = true
		} else {
			setIsValid(false)
			fileIsValid = false
		}
		//Here, we send this data to our one of two props (NewPlace.js or Auth.js)
		//In NewPlace or Auth, in their return statements, they have an onInput that
		//points to the inputHandler in the form-hook.js.  The inputHandler function
		//takes an id, a value, and a validity.
		props.onInput(props.id, pickedFile, fileIsValid)
	}

	//Pick Image button.  When you click on this, the Windows will open
	//up a box where you can navigate to your pictures and pick one.
	//The click method exists on the this dom node and will open the
	//file picker
	const pickImageHandler = () => {
		filePickerRef.current.click()
	}

	//Note the type is file, and since it's a picture, we're going to
	//accept jph, png, and jpeg.
	//We use display: none so that the file picker is initially hidden.
	//we'll need a button to display it.  Keep in mind though that
	//display: none will still allow it to be seen in the dom.
	//REMEMBER MATT:  this is JUST dealing with the image picker thing,
	//NOT the whole input form!
	//Anyway, once the user chooses a file, onChange will fire on the input element
	//so we have to provide a function for when that happens:  pickedHandler
	return (
		<div className='form-control'>
			<input // < - - this input thing right here is the image picker box.
				id={props.id} //          it is initially hidden
				ref={filePickerRef}
				style={{ display: 'none' }}
				type='file'
				accept='.jpg, .png, .jpeg'
				onChange={pickedHandler}
				name='image'
			/>
			<div className={`image-upload ${props.center && 'center'}`}>
				<div className='image-upload__preview'>
					{previewUrl && <img src={previewUrl} alt='Preview' />}
					{!previewUrl && <p>Please pick an image.</p>}
				</div>
				<Button type='button' onClick={pickImageHandler}>
					{/* PICK IMAGE */}
					FIND A PHOTO
				</Button>
			</div>
			{!isValid && <p>{props.errorText}</p>}
		</div>
	)
}

export default ImageUpload
