import React from 'react'
import SchedulesHomeNavigation from './SchedulesHomeNavigation'
import TeamSchedule from '../../schedule/TeamSchedule'

const SchedulesHome = () => {
	return (
		<div>
			<SchedulesHomeNavigation />
			<TeamSchedule />
		</div>
	)
}

export default SchedulesHome
