//This is for listing all CURRENT leagues

import React, { useEffect, useState } from 'react'
import './AdminLeagueList.css'
import AdminNavigation from './AdminNavigation'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import { NavLink } from 'react-router-dom'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import Modal from '../../shared/components/UIElements/Modal'
import { useHttpClient } from '../../shared/hooks/http-hook'

const AdminCurrentLeagueList = () => {
	const { sendRequest, clearError } = useHttpClient()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [teamId, setTeamId] = useState('')
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [showConfirmArchiveModal, setShowConfirmArchiveModal] = useState(false)
	const [teamDeleted, setTeamDeleted] = useState()
	const [teamArchived, setTeamArchived] = useState()
	const [teamToDelete, setTeamToDelete] = useState()
	const [loadedCurrentTeams, setLoadedCurrentTeams] = useState()

	//Let's get a list of all current teams
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			setTeamDeleted(false)
			setTeamArchived(false)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/teams/current'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedCurrentTeams(responseData.currentTeams)
				setIsLoading(false)
			} catch (err) {
				setError('ERROR: ' + err.message)
			}
			setTeamArchived(false)
			setIsLoading(false)
		}
		sendRequest()
	}, [setLoadedCurrentTeams, teamDeleted, teamArchived])

	const errorHandler = () => {
		setError(null)
	}

	loadedCurrentTeams &&
		console.log('loadedCurrentTeams: ' + JSON.stringify(loadedCurrentTeams))

	//******************************************************** */
	//
	//     Delete warning
	//
	//******************************************************** */
	const showDeleteWarningHandler = (e, teamname) => {
		const value = e.target.id
		console.log('value: ' + value)
		setTeamToDelete(teamname)
		setTeamId(loadedCurrentTeams[value]._id)
		setShowConfirmModal(true)
	}

	const cancelDeleteHandler = () => {
		setShowConfirmModal(false)
	}

	//console.log('league right here: ' + leagueId)
	const confirmDeleteHandler = async () => {
		setShowConfirmModal(false)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${teamId}/removeTeam`,
				'DELETE'
			)
		} catch (err) {}
		setTeamDeleted(true)
	}
	//******************************************************** */
	//
	//     Archive warning
	//
	//******************************************************** */
	const showArchiveWarningHandler = (e) => {
		const value = e.target.id
		/* console.log('value: ' + e.target.value)
		console.log('value: ' + loadedCurrentTeams._id) */
		setTeamId(loadedCurrentTeams[value]._id)
		setShowConfirmArchiveModal(true)
	}

	const cancelArchiveHandler = () => {
		setShowConfirmArchiveModal(false)
	}

	const confirmArchiveHandler = async () => {
		console.log('teamId: ' + teamId)
		setShowConfirmArchiveModal(false)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${teamId}/archiveCurrentToggleTeam`,
				'PATCH'
			)
		} catch (err) {}
		setTeamArchived(true)
	}
	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			<Modal
				show={showConfirmModal}
				onCancel={cancelDeleteHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to delete <h1>{teamToDelete}</h1>
						</p>
						<p className='modalText'>
							This will also delete all the rostered players.
						</p>
						<br></br>
						<button
							className='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelDeleteHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							style={{ color: 'red' }}
							danger
							onClick={confirmDeleteHandler}
						>
							DELETE TEAM
						</button>
					</React.Fragment>
				}
			></Modal>
			<Modal
				show={showConfirmArchiveModal}
				onCancel={cancelArchiveHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to archive this team?
						</p>
						<br></br>
						<button
							className='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelArchiveHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							danger
							onClick={confirmArchiveHandler}
						>
							ARCHIVE TEAM
						</button>
					</React.Fragment>
				}
			></Modal>
			{!isLoading && !loadedCurrentTeams && (
				<div className='AdminLeagues'>
					<AdminNavigation />
					<h1>No Current Teams Found!</h1>
					<div className='row'>
						<NavLink
							to={`/admin/team/new`}
							exact
							className='centeredButtonSmallMargin'
							style={{ display: 'block', width: 'auto', fontWeight: '700' }}
						>
							Create a New Team
						</NavLink>
					</div>
					<h5>OR</h5>
					<div className='row'>
						<NavLink
							to={`/admin/teams/archive`}
							exact
							className='centeredButtonSmallMargin'
							style={{ display: 'block', width: 'auto', fontWeight: '700' }}
						>
							View Archived Teams
						</NavLink>
					</div>
				</div>
			)}
			{!isLoading && loadedCurrentTeams && (
				<div className='AdminLeagues'>
					<AdminNavigation />
					<h1>Teams</h1>
					<div className='row'>
						<NavLink
							to={`/admin/team/new`}
							exact
							className='centeredButtonSmallMargin'
							style={{ display: 'block', width: 'auto', fontWeight: '700' }}
						>
							Create a New Team
						</NavLink>
					</div>
					<h5>OR</h5>
					<div className='row'>
						<NavLink
							to={`/admin/teams/archive`}
							exact
							className='centeredButtonSmallMargin'
							style={{ display: 'block', width: 'auto', fontWeight: '700' }}
						>
							View Archived Teams
						</NavLink>
					</div>
					<table id='leagues'>
						<thead>
							<tr>
								<th>Team Name</th>
								<th>Year</th>
								<th>Players</th>
								<th></th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{loadedCurrentTeams.map((team, key) => {
								return (
									<tr key={key}>
										<td>{team.teamName}</td>
										<td>{team.year}</td>
										<td>
											<NavLink
												className='navlinks'
												to={`/admin/${team.teamName}/${team.year}/players`}
												exact
											>
												Players
											</NavLink>
										</td>
										<td>
											<NavLink
												className='navlinks'
												to={`/admin/updateTeam/${team._id}`}
												exact
											>
												Edit
											</NavLink>
										</td>
										<td>
											<button
												className='buttonButton'
												id={key}
												onClick={(e) => showArchiveWarningHandler(e)}
											>
												Archive
											</button>
										</td>
										<td>
											<button
												className='buttonButton'
												style={{ color: 'red' }}
												id={key}
												onClick={(e) =>
													showDeleteWarningHandler(e, team.teamName)
												}
											>
												DELETE
											</button>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminCurrentLeagueList
