import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Input from '../../shared/components/FormElements/Input'
//import Button from '../../shared/components/UIElements/Button'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import { VALIDATOR_REQUIRE } from '../../shared/util/validators'
import './NewPlayerForm.css'
import AdminNavigation from '../../admin/components/AdminNavigation'
import { useForm } from '../../shared/hooks/form-hook'
import { useHttpClient } from '../../shared/hooks/http-hook'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import ImageUpload from '../../shared/components/FormElements/ImageUpload'

const PlayerPhotoForm = () => {
	const { isLoading, error, sendRequest, clearError } = useHttpClient()
	const [file, setFile] = useState()
	const [firstName, setFirstName] = useState()
	const [lastName, setLastName] = useState()
	const [playerId, setPlayerId] = useState()
	const rosterPlayerId = useParams().playerId //This is the rosterPlayer id
	let navigate = useNavigate()

	//only need setFormData in Update
	const [formState, inputHandler, setFormData] = useForm()

	//call to getPlayerDataByRosterId
	//First, we want to get the roster players name to display on the form
	useEffect(() => {
		const fetchRosterPlayerData = async () => {
			try {
				const responseData = await sendRequest(
					`${process.env.REACT_APP_BACKEND_URL}/admin/rosterPlayer/${rosterPlayerId}`
				)
				setFirstName(responseData.player.firstName)
				setLastName(responseData.player.lastName)
				setPlayerId(responseData.player.playerId)
			} catch (err) {
				console.log('OH NO! ' + err)
			}
		}
		fetchRosterPlayerData()
	}, [sendRequest, rosterPlayerId])
	//
	//
	//
	const onFileChange = (e) => {
		let pickedFile

		if (e.target.files && e.target.files.length === 1) {
			pickedFile = e.target.files[0]
			console.log('pickedFile: ' + pickedFile)
			setFile(pickedFile)
		}
	}
	//
	//
	//Next, we want to make a call to uploadPhoto within the player-controller
	const playerPhotoSubmitHandler = async (event) => {
		console.log('inside playerPhotoSubmitHandler ' + formState.inputs)
		event.preventDefault()
		try {
			const formData = new FormData()

			formData.append('image', formState.inputs.image.value)
			const responseData = await sendRequest(
				process.env.REACT_APP_BACKEND_URL + `/player/${rosterPlayerId}/photo`,
				'POST',
				formData
			)
			navigate(`/${playerId}/history`)
		} catch (err) {
			console.log('upload photo error: ' + err)
		}
	}

	if (isLoading) {
		return (
			<div className='center'>
				<LoadingSpinner />
			</div>
		)
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={clearError} />
			{!isLoading && firstName && (
				<div>
					<AdminNavigation />
					<main>
						<h2>Add a Player Photo</h2>
						<h3>
							{firstName} {lastName}
						</h3>
						<form className='team-form' onSubmit={playerPhotoSubmitHandler}>
							<ImageUpload
								center
								id='image'
								//name='image'
								onInput={inputHandler}
							/>
							<div className='addPhotoBtn'>
								<AdminButton type='submit'>Add Selected Photo</AdminButton>
							</div>
						</form>
					</main>
				</div>
			)}
		</React.Fragment>
	)
}

export default PlayerPhotoForm
