//Create a roster for each team.
//Even while not logged in, you can see a list of players within the chosen team.
//This here is a page that OUTPUTS that list.
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import './Players.css'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import Footer from '../../shared/components/UIElements/Footer'
import StatsAndStandingsHome from '../../shared/components/Navigation/StatsAndStandingsHome'
import teamLogo from '../../shared/components/images/new_logo_gray_background.jpg'

const RosterAndSchedulePage = () => {
	console.log('inside RosterAndSchedulePage')
	const incomingTeamId = useParams().teamId
	console.log('incomingTeamId: ' + incomingTeamId)
	let navigate = useNavigate()
	//
	const [error, setError] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [rosteredPlayers, setRosteredPlayers] = useState()
	const [assignedPlayers, setAssignedPlayers] = useState()
	const [gamesList, setGamesList] = useState()
	const [wins, setWins] = useState()
	const [losses, setLosses] = useState()
	const [overtimeLosses, setOvertimeLosses] = useState()
	const [shootoutLosses, setShootoutLosses] = useState()
	const [ties, setTies] = useState()
	const [teamName, setTeamName] = useState()
	const [year, setYear] = useState()
	const [teamDropdownCreated, setTeamDropdownCreated] = useState(false)
	const [teamArray, setTeamArray] = useState([])
	const [loadedTeams, setLoadedTeams] = useState()
	//
	const [teamId, setTeamId] = useState()
	//
	//
	//
	//Call backend to get all the current teams for our dropdowns
	//     getCurrentTeam
	useEffect(() => {
		console.log('useEffect')
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/teams/current'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				//console.log('responseData: ' + JSON.stringify(responseData))
				setLoadedTeams(responseData.currentTeams)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			//
			setIsLoading(false)
		}
		sendRequest()
	}, [])
	//
	//
	//This creates an array from the loadedTeams.  It will be our 'Select a Team' dropdown:
	if (loadedTeams && !isLoading && !teamDropdownCreated) {
		const innerTeamArray = []
		loadedTeams.forEach((team) => {
			const teamDisplay = team.teamName + ' - ' + team.year
			innerTeamArray.push(teamDisplay)
		})
		//NEW  This was added for leagues that have divisions
		let uniqueArray = []
		const duplicateFilter = innerTeamArray.filter((element) => {
			if (!uniqueArray.includes(element)) {
				uniqueArray.push(element)

				return true
			}
			return false
		})
		//end NEW
		setTeamArray(duplicateFilter)
		setTeamDropdownCreated(true)
	}
	//
	//
	//
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	let rem
	if (!assignedPlayers) {
		rem = '12rem'
	}
	//
	if (assignedPlayers) {
		//console.log('assignedPlayers: ' + assignedPlayers)
		if (assignedPlayers > 10) {
			rem = '2rem'
		} else {
			rem = '10rem'
		}
	}
	//
	//
	//This will sort the players by points, with goals being the tie-breaker
	//
	if (rosteredPlayers) {
		rosteredPlayers.forEach((player) => {
			player.points = player.goals + player.assists
		})
		//
		rosteredPlayers.sort((a, b) => (a.points > b.points ? 1 : -1))
		//
		rosteredPlayers.sort((a, b) =>
			a.points === b.points && a.goals < b.goals ? 1 : -1
		)
	}
	//
	//
	let selectedTeam
	//
	//
	//
	//This function is if a user selects a team from the dropdown
	//
	const handleChange = async (e) => {
		if (e.target.value !== 'Select a Team') {
			e.preventDefault()
			const selectedValue = e.target.value
			//console.log('selectedValue: ' + selectedValue)
			selectedTeam = loadedTeams[selectedValue]
			const teamId = selectedTeam._id
			//console.log('teamId: ' + teamId)
			setTeamId(teamId)
			navigate(`/statsandstandings/${teamId}`)
			//
			//
			//Maybe make a backend call right here to get this teams data
			//makes a call to league-controller, getPlayersOnTeam2.
			setIsLoading(true)
			try {
				const response = await fetch(
					// `${process.env.REACT_APP_BACKEND_URL}/league/${leagueId}/${session}/${year}/${teamName}`
					`${process.env.REACT_APP_BACKEND_URL}/league/${teamId}/playerStats`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}
				setRosteredPlayers(responseData.rosteredPlayers)
				setAssignedPlayers(responseData.assignedPlayers)
				setWins(responseData.wins)
				setLosses(responseData.losses)
				setOvertimeLosses(responseData.overtimeLosses)
				setShootoutLosses(responseData.shootoutLosses)
				setGamesList(responseData.allGamesAndEventsArray)
				setTies(responseData.ties)
				setTeamName(responseData.teamName)
				setYear(responseData.year)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
	}
	//
	//This is if theres an incomingTeamId, which means that the user was on the schedule page for
	//a team, then just clicked the 'PLAYER STATS' tab at the top.  This prevents the user from having
	//to select the team again.
	//
	//
	useEffect(() => {
		const incomingTeamFunction = async (e) => {
			const teamId = incomingTeamId
			console.log('inside incomingTeamFunction: ' + teamId)
			setTeamId(teamId)
			//
			//
			//Maybe make a backend call right here to get this teams data
			//makes a call to league-controller, getPlayersOnTeam2.
			setIsLoading(true)
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/league/${teamId}/playerStats`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}
				setRosteredPlayers(responseData.rosteredPlayers)
				setAssignedPlayers(responseData.assignedPlayers)
				setWins(responseData.wins)
				setLosses(responseData.losses)
				setOvertimeLosses(responseData.overtimeLosses)
				setShootoutLosses(responseData.shootoutLosses)
				setGamesList(responseData.allGamesAndEventsArray)
				setTies(responseData.ties)
				setTeamName(responseData.teamName)
				setYear(responseData.year)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		if (incomingTeamId) {
			incomingTeamFunction()
		}
	}, [])
	//
	//
	//
	//
	return (
		<div style={{ overflowX: 'hidden' }}>
			<React.Fragment>
				<StatsAndStandingsHome teamId={teamId} />
				<ErrorModal error={error} onClear={errorHandler} />
				{isLoading && (
					<div className='center' style={{ marginTop: '10rem' }}>
						<LoadingSpinner />
					</div>
				)}
				{!isLoading && !rosteredPlayers && (
					<React.Fragment>
						<div className='Roster' style={{ marginTop: '6rem' }}>
							<div>
								<img src={teamLogo} className='teamLogo' />
							</div>
							<h2>{year} Player Stats</h2>
							<div style={{ textAlign: 'center' }}>
								{!isLoading && loadedTeams && (
									<form className='form-control-lg'>
										<select
											className='my-1 mr-sm-2 selectLeagueDropdown'
											onChange={handleChange}
											style={{
												background: 'white',
												borderWidth: '3px',
												width: '30vh',
												fontSize: 'medium',
												fontFamily: 'Montserrat, Work Sans, sans-serif',
											}}
										>
											<option defaultValue>Select a Team</option>
											{teamArray.map((team, i) => (
												<option value={i} key={i}>
													{team.toUpperCase()}
												</option>
											))}
										</select>
									</form>
								)}
							</div>
						</div>
						<div style={{ marginTop: `${rem}` }}>
							<Footer />
						</div>
					</React.Fragment>
				)}
				{/* //
			//
			//   
			//
			// */}
				{!isLoading && rosteredPlayers && (
					<React.Fragment>
						<div className='Roster' style={{ marginTop: '6rem' }}>
							<div>
								<img src={teamLogo} className='teamLogo' />
							</div>
							{/* <SelectPreviousTeam /> */}
							<div style={{ textAlign: 'center' }}>
								{!isLoading && loadedTeams && (
									<form className='form-control-lg'>
										<select
											className='my-1 mr-sm-2 selectLeagueDropdown'
											onChange={handleChange}
											style={{
												background: 'white',
												borderWidth: '3px',
												width: '30vh',
												fontSize: 'medium',
												fontFamily: 'Montserrat, Work Sans, sans-serif',
											}}
										>
											<option defaultValue>Select a Team</option>
											{teamArray.map((team, i) => (
												<option value={i} key={i}>
													{team.toUpperCase()}
												</option>
											))}
										</select>
									</form>
								)}
							</div>
							<h2>
								{teamName} - {year}
							</h2>
							<h3 className='h3Center'>Player Stats</h3>
							<p style={{ textAlign: 'center' }}>
								(Click on a player's name to go to their individual page!)
							</p>
							<table id='roster'>
								<thead>
									<tr>
										<th>Number</th>
										<th>Name</th>
										<th>G</th>
										<th>A</th>
										<th>Total Points</th>
										<th>PIM</th>
									</tr>
								</thead>
								<tbody>
									{rosteredPlayers.map((player, key) => {
										{
											return (
												<tr key={key}>
													<td>{player.number}</td>
													<td className='tableDataTeamName'>
														<Link
															style={{
																textDecoration: 'none',
																color: 'black',
																fontWeight: 600,
															}}
															to={`/${player.playerId}/history`}
														>
															{player.firstName} {player.lastName}{' '}
															{player.isCaptain && '(Captain)'}{' '}
															{player.isGoalie && '(Goalie)'}
														</Link>
													</td>
													<td>{player.goals}</td>
													<td>{player.assists}</td>
													<td>{player.goals + player.assists}</td>
													<td>{player.pim}</td>
												</tr>
											)
										}
									})}
								</tbody>
							</table>
						</div>

						<div style={{ marginTop: `${rem}` }}>
							<Footer />
						</div>
					</React.Fragment>
				)}
			</React.Fragment>
		</div>
	)
}

export default RosterAndSchedulePage
