import React, { useState } from 'react'
import './AdminNavigation.css'
import AdminHeader from './AdminHeader'
import NavLinks from './NavLinks'
import { Link } from 'react-router-dom'
import SideDrawer from './SideDrawer'
import Backdrop from '../../shared/components/UIElements/Backdrop'

const AdminNavigation = (props) => {
	const [drawerIsOpen, setDrawerIsOpen] = useState(false)

	const openDrawerHandler = () => {
		setDrawerIsOpen(true)
	}

	const closeDrawerHandler = () => {
		setDrawerIsOpen(false)
	}

	return (
		<React.Fragment>
			{drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
			<SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
				<nav className='main-navigation__drawer-nav'>
					<NavLinks />
				</nav>
			</SideDrawer>
			<AdminHeader>
				<button
					className='main-navigation__menu-btn'
					onClick={openDrawerHandler}
				>
					<span />
					<span />
					<span />
				</button>
				<h1 className='main-navigation__title'>
					<Link to='/admin/team/current'>Admin</Link>
				</h1>
				<nav className='main-navigation__header-nav'>
					<NavLinks />
				</nav>
			</AdminHeader>
		</React.Fragment>
	)
}

export default AdminNavigation
