import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './HomeNavigation.css'
import HomeHeader from './HomeHeader'
import HomeNavLinks from './HomeNavLinks'
import SideDrawer from '../../../admin/components/SideDrawer'
import Backdrop from '../UIElements/Backdrop'

const SchedulesHomeNavigation = (props) => {
	console.log('inside SchedulesHomeNavigation')
	console.log('props: ' + JSON.stringify(props))
	const [drawerIsOpen, setDrawerIsOpen] = useState(false)

	const openDrawerHandler = () => {
		setDrawerIsOpen(true)
	}

	const closeDrawerHandler = () => {
		setDrawerIsOpen(false)
	}

	return (
		<React.Fragment>
			{drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
			<SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
				<nav className='home-navigation__drawer-nav'>
					<HomeNavLinks teamId={props.teamId} />
				</nav>
			</SideDrawer>
			<HomeHeader>
				<button
					className='home-navigation__menu-btn'
					onClick={openDrawerHandler}
				>
					<span />
					<span />
					<span />
				</button>
				<h1 className='home-navigation__title'>
					<Link to='/schedule'>SCHEDULE</Link>
				</h1>
				<nav className='home-navigation__header-nav'>
					<HomeNavLinks teamId={props.teamId} />
				</nav>
			</HomeHeader>
			{/* <SelectLeague /> */}
		</React.Fragment>
	)
}

export default SchedulesHomeNavigation
