import React, { useContext } from 'react'
import './HomeNavLinks.css'
import { NavLink, Link } from 'react-router-dom'
import { AuthContext } from '../../context/auth-context'

const HomeNavLinks = (props) => {
	const auth = useContext(AuthContext)
	//console.log('auth: ' + JSON.stringify(auth))

	//console.log('HomeNavLinks props: ' + JSON.stringify(props.leagueId))
	const teamId = props.teamId
	console.log('propsString: ' + teamId)

	let page

	if (auth.isLoggedIn) {
		//console.log('User is logged in!')
		page = '/admin/team/current'
	} else {
		//console.log('User is NOT logged in!')
		page = '/admin/login'
	}

	return (
		<ul className='home-nav-links'>
			<li>
				<NavLink to={'/faq'}>FAQ</NavLink>
			</li>
			<li>
				<NavLink to={'/info'}>INFO</NavLink>
			</li>
			{props.teamId && (
				<li>
					<NavLink to={`/schedule/${teamId}`}>SCHEDULE</NavLink>
				</li>
			)}
			{!props.teamId && (
				<li>
					<NavLink to={'/schedule'}>SCHEDULE</NavLink>
				</li>
			)}
			{props.teamId && (
				<li>
					<NavLink to={`/statsandstandings/${teamId}`}>PLAYER STATS</NavLink>
				</li>
			)}
			{!props.teamId && (
				<li>
					<NavLink to={'/statsandstandings'}>PLAYER STATS</NavLink>
				</li>
			)}
			{/* <li>
				<NavLink to={'/history'}>PREVIOUS SEASONS</NavLink>
			</li> */}
			<li>
				<NavLink to={'/rosters'}>TRYOUT RESULTS</NavLink>
			</li>
			<li>
				<a href='/'>HOME</a>
			</li>
			<li>
				<NavLink to={page}>ADMIN</NavLink>
			</li>
		</ul>
	)
}

export default HomeNavLinks
