//Create a roster for each team.
//Even while not logged in, you can see a list of players within the chosen team.
//This here is a page that OUTPUTS that list.
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import './Players.css'
import Card from '../../shared/components/UIElements/Card'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import Footer from '../../shared/components/UIElements/Footer'
import RostersHomeNavigation from './RostersHomeNavigation'
import teamLogo from '../../shared/components/images/new_logo_gray_background.jpg'
import { NavLink } from 'react-router-dom'

const Rosters = () => {
	console.log('inside Rosters')
	const [error, setError] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [assignedPlayers, setAssignedPlayers] = useState()
	const [selectedDivision, setSelectedDivision] = useState()
	const [loadedTryoutResults, setLoadedTryoutResults] = useState()
	const [teamsToDisplay, setTeamsToDisplay] = useState([])
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//
	//
	let selectedTeam

	//Call backend to getTryoutResults
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/tryoutresults'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedTryoutResults(responseData.allTryoutResults)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [setLoadedTryoutResults])

	let teamArray

	/* if (loadedTeams && !isLoading) {
		teamArray = []
		loadedTeams.forEach((team) => {
			const teamDisplay = team.teamName + ' ' + team.year
			teamArray.push(teamDisplay)
		})
	} */

	let navigate = useNavigate()

	/* const handleChange = async (e) => {
		console.log('click')
	} */

	loadedTryoutResults &&
		console.log('loadedTryoutResults: ' + JSON.stringify(loadedTryoutResults))

	let resultsToDisplay
	resultsToDisplay = []

	const handleChange = async (e) => {
		if (e.target.value !== 'Select a Division...') {
			//e.preventDefault()

			setSelectedDivision(e.target.value)
			//
			loadedTryoutResults &&
				loadedTryoutResults.forEach((team) => {
					if (team.division === e.target.value) {
						resultsToDisplay.push(team)
						console.log('YEP')
					}
					if (team.division !== e.target.value) {
						console.log('NOPE')
					}
				})

			setTeamsToDisplay(resultsToDisplay)
			//
			//Maybe make a backend call right here to get this old teams data
			//  makes a call to league-controller, getPlayersOnOldTeam.  also returns old games
			/* setIsLoading(true)
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/league/oldRosterAndSchedule/${teamId}`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false) */
		}
	}
	//
	console.log('resultsToDisplay: ' + JSON.stringify(teamsToDisplay))
	//
	//
	//
	let rem
	if (!assignedPlayers) {
		rem = '12rem'
	}
	//
	if (assignedPlayers) {
		console.log('assignedPlayers: ' + assignedPlayers)
		if (assignedPlayers > 10) {
			rem = '2rem'
		} else {
			rem = '10rem'
		}
	}
	//
	//
	return (
		<div style={{ overflowX: 'hidden' }}>
			<React.Fragment>
				<RostersHomeNavigation />
				<ErrorModal error={error} onClear={errorHandler} />
				{isLoading && (
					<div className='center' style={{ marginTop: '10rem' }}>
						<LoadingSpinner />
					</div>
				)}
				{!isLoading && !selectedDivision && (
					<React.Fragment>
						<div className='Roster' style={{ marginTop: '6rem' }}>
							<div style={{ marginBottom: '1.5rem' }}>
								<img src={teamLogo} className='teamLogo' />
							</div>
							<h2>Select a division to see the results:</h2>
							<div style={{ textAlign: 'center' }}>
								{!isLoading && loadedTryoutResults && (
									<form className='form-control-lg'>
										<div
											className='form-group fg-line net'
											style={{ marginBottom: '2rem' }}
										>
											<select
												id='year'
												type='text'
												tabIndex='9'
												placeholder='Division'
												className='rform-control selectDivisionDropdown'
												onChange={handleChange}
												required
											>
												<option defaultValue>Division...</option>
												<option value='Chipmunk'>Chipmunk</option>
												<option value='Penguin'>Penguin</option>
												<option value='Beaver'>Beaver</option>
												<option value='Cadet'>Cadet</option>
												<option value='Freshmen'>Freshmen</option>
												<option value='Other'>Other</option>
											</select>
										</div>
									</form>
								)}
							</div>
							{/* 	<div className='row'>
								<a
									href='https://docs.google.com/forms/d/e/1FAIpQLSdEjP3mtZGPaImAWUSFNbXSjvKeOEwgE5Bze7ci68u9n3JkOA/viewform'
									className='centeredButtonSmallMargin'
									target='_blank'
									style={{
										display: 'block',
										width: 'auto',
										fontWeight: '700',
										backgroundColor: '#fee03a',
										color: '#410009',
										padding: '1%',
										borderRadius: '4px',
										textDecoration: 'none',
										border: 'solid',
									}}
								>
									Accept or Decline Registration HERE
								</a>
							</div> */}
							<div className='disclaimer'>
								<div className='row'>
									<a
										href='https://docs.google.com/forms/d/e/1FAIpQLSdEjP3mtZGPaImAWUSFNbXSjvKeOEwgE5Bze7ci68u9n3JkOA/viewform'
										className='centeredButtonSmallMargin'
										target='_blank'
										style={{
											display: 'block',
											width: 'auto',
											fontWeight: '700',
											backgroundColor: '#fee03a',
											color: '#410009',
											padding: '3%',
											borderRadius: '4px',
											textDecoration: 'none',
											border: 'solid',
											borderColor: 'black',
										}}
									>
										Accept or Decline Registration HERE
									</a>
								</div>
								<p>Parents & Players,</p> ​
								<p>
									We are excited for the 2025 Screaming Eagles season. Below you
									will find each teams roster listed by tryout number. Choosing
									teams this year was difficult as we saw so much talent across
									the board from every division. We thank each and every player
									that came and tried out with us. We are happy to announce our
									2025 Rosters and congratulate our returning and new players
									for this season. Please keep in mind the following:
								</p>
								<ul>
									<li>
										<p>
											<a
												href='https://docs.google.com/document/d/1Hn_7S1aPZQoINMkJwbKcghGs-1FmYilFqHmaxeme_Sk/edit?tab=t.0'
												target='_blank'
												style={{ color: 'white' }}
											>
												2025 Hand Book - You must review the handbook linked
												here prior to accepting your position.
											</a>
										</p>
									</li>
									<li>
										<p>
											If your child was selected, commitments are due by
											midnight on October 17, 2024. The information collected
											during registration online is how the coaches will
											communicate with the parents. Coaches will send an email
											once registrations are collected and the registration
											deadline has passed.
										</p>
									</li>
									<li>
										<p>
											Note that there may be movement in the below rosters
											depending on players accepting or declining their team
											offer.
										</p>
									</li>
									<li>
										<p>
											We ask parents to understand that this is tournament
											travel dek hockey. Playing time is not guaranteed, it is
											earned. Earning playing time comes in the forms of
											practice attendance, tournament attendance, effort, skill,
											and attitude. Attendance to practice is mandatory and
											expected.
										</p>
									</li>
									<li>
										<p>Registration fees are non-refundable.</p>
									</li>
								</ul>
							</div>
						</div>
						<div style={{ marginTop: `${rem}` }}>
							<Footer />
						</div>
					</React.Fragment>
				)}
				{!isLoading && selectedDivision && (
					<React.Fragment>
						<div
							className='Roster'
							style={{ marginTop: '6rem', marginBottom: '0rem' }}
						>
							<div style={{ marginBottom: '1.5rem' }}>
								<img src={teamLogo} className='teamLogo' />
							</div>
							<h2>Select a division to see the results:</h2>
							<div style={{ textAlign: 'center' }}>
								{!isLoading && loadedTryoutResults && (
									<form className='form-control-lg'>
										<div
											className='form-group fg-line net'
											style={{ marginBottom: '2rem' }}
										>
											<select
												id='year'
												type='text'
												tabIndex='9'
												placeholder='Division'
												className='rform-control selectDivisionDropdown'
												onChange={handleChange}
												required
											>
												<option defaultValue>Division...</option>
												<option value='Chipmunk'>Chipmunk</option>
												<option value='Penguin'>Penguin</option>
												<option value='Beaver'>Beaver</option>
												<option value='Cadet'>Cadet</option>
												<option value='Freshmen'>Freshmen</option>
												<option value='Other'>Other</option>
											</select>
										</div>
									</form>
								)}
							</div>
						</div>

						<div>
							{teamsToDisplay.map((team, key) => {
								return (
									<div className='rosterContainer'>
										<div className='row'>
											<a
												href='https://docs.google.com/forms/d/e/1FAIpQLSdEjP3mtZGPaImAWUSFNbXSjvKeOEwgE5Bze7ci68u9n3JkOA/viewform'
												className='centeredButtonSmallMargin'
												target='_blank'
												style={{
													display: 'block',
													width: 'auto',
													fontWeight: '700',
													backgroundColor: '#fee03a',
													color: '#410009',
													padding: '3%',
													borderRadius: '4px',
													textDecoration: 'none',
													border: 'solid',
													borderColor: 'black',
												}}
											>
												Accept or Decline Registration HERE
											</a>
										</div>
										<h1>
											{team.year} {team.division} {team.teamName}
										</h1>
										<h3>Head Coach: {team.headCoach}</h3>
										{team.player1 && <h5>1. {'  ' + team.player1}</h5>}
										{team.player2 && <h5>2. {'  ' + team.player2}</h5>}
										{team.player3 && <h5>3. {'  ' + team.player3}</h5>}
										{team.player4 && <h5>4. {'  ' + team.player4}</h5>}
										{team.player5 && <h5>5. {'  ' + team.player5}</h5>}
										{team.player6 && <h5>6. {'  ' + team.player6}</h5>}
										{team.player7 && <h5>7. {'  ' + team.player7}</h5>}
										{team.player8 && <h5>8. {'  ' + team.player8}</h5>}
										{team.player9 && <h5>9. {'  ' + team.player9}</h5>}
										{team.player10 && <h5>10. {'  ' + team.player10}</h5>}
										{team.player11 && <h5>11. {'  ' + team.player11}</h5>}
										{team.player12 && <h5>12. {'  ' + team.player12}</h5>}
										{team.player13 && <h5>13. {'  ' + team.player13}</h5>}
										{team.player14 && <h5>14. {'  ' + team.player14}</h5>}
										{team.player15 && <h5>15. {'  ' + team.player15}</h5>}
										{team.player16 && <h5>16. {'  ' + team.player16}</h5>}
										{team.player17 && <h5>17. {'  ' + team.player17}</h5>}
										{team.player18 && <h5>18. {'  ' + team.player18}</h5>}
										{team.player19 && <h5>19. {'  ' + team.player19}</h5>}
										{team.player20 && <h5>20. {'  ' + team.player20}</h5>}
										{team.player21 && <h5>21. {'  ' + team.player21}</h5>}
										{team.player22 && <h5>22. {'  ' + team.player22}</h5>}
										{team.player23 && <h5>23. {'  ' + team.player23}</h5>}
										{team.player24 && <h5>24. {'  ' + team.player24}</h5>}
										{team.player25 && <h5>25. {'  ' + team.player25}</h5>}
									</div>
								)
							})}
						</div>
						<div className='disclaimer'>
							<div className='row'>
								<a
									href='https://docs.google.com/forms/d/e/1FAIpQLSdEjP3mtZGPaImAWUSFNbXSjvKeOEwgE5Bze7ci68u9n3JkOA/viewform'
									className='centeredButtonSmallMargin'
									target='_blank'
									style={{
										display: 'block',
										width: 'auto',
										fontWeight: '700',
										backgroundColor: '#fee03a',
										color: '#410009',
										padding: '3%',
										borderRadius: '4px',
										textDecoration: 'none',
										border: 'solid',
										borderColor: 'black',
									}}
								>
									Accept or Decline Registration HERE
								</a>
							</div>
							<p>Parents & Players,</p> ​
							<p>
								We are excited for the 2025 Screaming Eagles season. Below you
								will find each teams roster listed by tryout number. Choosing
								teams this year was difficult as we saw so much talent across
								the board from every division. We thank each and every player
								that came and tried out with us. We are happy to announce our
								2025 Rosters and congratulate our returning and new players for
								this season. Please keep in mind the following:
							</p>
							<ul>
								<li>
									<p>
										<a
											href='https://docs.google.com/document/d/1Hn_7S1aPZQoINMkJwbKcghGs-1FmYilFqHmaxeme_Sk/edit?tab=t.0'
											target='_blank'
											style={{ color: 'white' }}
										>
											2025 Hand Book - You must review the handbook linked here
											prior to accepting your position.
										</a>
									</p>
								</li>
								<li>
									<p>
										If your child was selected, commitments are due by midnight
										on October 17, 2024. The information collected during
										registration online is how the coaches will communicate with
										the parents. Coaches will send an email once registrations
										are collected and the registration deadline has passed.
									</p>
								</li>
								<li>
									<p>
										Note that there may be movement in the below rosters
										depending on players accepting or declining their team
										offer.
									</p>
								</li>
								<li>
									<p>
										We ask parents to understand that this is tournament travel
										dek hockey. Playing time is not guaranteed, it is earned.
										Earning playing time comes in the forms of practice
										attendance, tournament attendance, effort, skill, and
										attitude. Attendance to practice is mandatory and expected.
									</p>
								</li>
								<li>
									<p>Registration fees are non-refundable.</p>
								</li>
							</ul>
						</div>
						<div style={{ marginTop: `${rem}` }}>
							<Footer />
						</div>
					</React.Fragment>
				)}
			</React.Fragment>
		</div>
	)
}

export default Rosters
