import React, { useEffect, useState } from 'react'
import './AdminTeamList.css'
import { NavLink } from 'react-router-dom'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import AdminNavigation from './AdminNavigation'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import Modal from '../../shared/components/UIElements/Modal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import { useHttpClient } from '../../shared/hooks/http-hook'

const AdminFAQList = () => {
	const { sendRequest, clearError } = useHttpClient()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [loadedFaqs, setLoadedFaqs] = useState()
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [deletedFaqId, setDeletedFaqId] = useState('')
	const [faqDeleted, setFaqDeleted] = useState()

	//First, lets tap into the following route on the backend to get all faqs:
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			setFaqDeleted(false)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + `/admin/faqs`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedFaqs(responseData.allFaqs)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [faqDeleted])

	const errorHandler = () => {
		setError(null)
	}

	//******************************************************** */
	//
	//     Delete warning
	//
	//******************************************************** */
	const showDeleteWarningHandler = (e) => {
		const value = e.target.id
		setDeletedFaqId(loadedFaqs[value]._id)
		setShowConfirmModal(true)
	}

	const cancelDeleteHandler = () => {
		setShowConfirmModal(false)
	}

	const confirmDeleteHandler = async () => {
		setShowConfirmModal(false)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${deletedFaqId}/removeFaq`,
				'DELETE'
			)
		} catch (err) {}
		setFaqDeleted(true)
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			<Modal
				show={showConfirmModal}
				onCancel={cancelDeleteHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to delete this FAQ?
						</p>
						<br></br>
						<button
							className='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelDeleteHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							style={{ color: 'red' }}
							danger
							onClick={confirmDeleteHandler}
						>
							DELETE FAQ
						</button>
					</React.Fragment>
				}
			></Modal>
			{!isLoading && loadedFaqs && (
				<div className='Teams'>
					<AdminNavigation />
					<h1>FAQ's</h1>
					<table id='teams'>
						<thead>
							<tr>
								<th>Question</th>
								<th>Answer</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{loadedFaqs.map((faq, key) => {
								return (
									<tr key={key}>
										<td>{faq.question}</td>
										<td>{faq.answer}</td>
										<td>
											<NavLink
												className='navlinks'
												to={`/admin/updateFaq/${faq._id}`}
												exact
											>
												Edit
											</NavLink>
										</td>
										<td>
											<button
												className='buttonButton'
												style={{ color: 'red' }}
												id={key}
												onClick={(e) => showDeleteWarningHandler(e)}
											>
												DELETE
											</button>
										</td>
									</tr>
								)
							})}
							<div style={{ marginBottom: '4rem' }}>
								<tr style={{ backgroundColor: '#e0e0e0' }}>
									<AdminButton
										to={`/admin/faq/new`}
										exact
										style={{
											color: 'white',
											textDecoration: 'none',
										}}
									>
										Add a new FAQ
									</AdminButton>
								</tr>
							</div>
						</tbody>
					</table>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminFAQList
