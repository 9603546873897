//Player history
//This will go through all the leagues/teams that a player used to play for
//and show all their stats.
import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import Footer from '../../shared/components/UIElements/Footer'
import './Players.css'
import StatsAndStandingsHome from '../../shared/components/Navigation/StatsAndStandingsHome'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import teamLogo from '../../shared/components/images/new_logo_gray_background.jpg'
import YouTube from 'react-youtube'
var getYouTubeID = require('get-youtube-id')

const PlayerHistory = () => {
	//const { sendRequest, clearError } = useHttpClient()
	const [error, setError] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [loadedRosters, setLoadedRosters] = useState()
	const [firstName, setFirstName] = useState()
	const [lastName, setLastName] = useState()
	const [middleInitial, setMiddleInitial] = useState()
	const [number, setNumber] = useState()
	const [photo, setPhoto] = useState()
	const [listOfVideos, setListOfVideos] = useState()
	const [ids, setIds] = useState([])
	const [videoTitles, setVideoTitles] = useState([])
	const [videoCaptions, setVideoCaptions] = useState([])
	const [onlyBeenGoalie, setOnlyBeenGoalie] = useState()
	const [hasPlayedOutAndBeenGoalie, setHasPlayedOutAndBeenGoalie] = useState()
	const [neverPlayedGoalie, setNeverPlayedGoalie] = useState()
	const [processDone, setProcessDone] = useState(false)
	const [goalieStats, setGoalieStats] = useState(false)
	const [goalieStatsByRosterSeason, setGoalieStatsByRosterSeason] = useState()
	const [goalieAndNonGoalieStats, setGoalieAndNonGoalieStats] = useState()
	const [seasonsWherePlayerWasGoalie, setSeasonsWherePlayerWasGoalie] =
		useState()
	const [seasonsWherePlayerWasNotGoalie, setSeasonsWherePlayerWasNotGoalie] =
		useState()
	//
	const playerId = useParams().playerId
	//
	//
	//
	//Make a call to getPlayerHistoryByPlayerId in player-controller
	//UPDATE:  Were also going to see if there's any videos of this player
	//
	//
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/player/${playerId}/history`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				console.log('responseData: ' + JSON.stringify(responseData))

				setLoadedRosters(responseData.listOfRosters)
				setFirstName(responseData.firstName)
				setLastName(responseData.lastName)
				setMiddleInitial(responseData.middleInitial)
				setNumber(responseData.number)
				setPhoto(responseData.photo)
				setListOfVideos(responseData.listOfVideos)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [playerId, firstName, lastName, middleInitial])
	//
	//
	//
	//
	//
	//
	let arrayOfTitles, arrayOfIds, arrayOfCaptions
	arrayOfTitles = []
	arrayOfIds = []
	arrayOfCaptions = []
	if (listOfVideos && !isLoading && ids.length === 0) {
		listOfVideos.forEach((video) => {
			arrayOfTitles.push(video.videoTitle)
			arrayOfIds.push(getYouTubeID(video.videoURL))
			arrayOfCaptions.push(video.videoCaption)
		})
	}
	//
	if (arrayOfTitles.length > 0) {
		setVideoTitles(arrayOfTitles)
	}
	if (arrayOfIds.length > 0) {
		setIds(arrayOfIds)
	}
	if (arrayOfCaptions.length > 0) {
		setVideoCaptions(arrayOfCaptions)
	}
	//
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//  Goalie stuff
	//
	//
	let wasGoalie, wasOut
	wasGoalie = 0
	wasOut = 0
	if (loadedRosters && !processDone) {
		setIsLoading(true)
		loadedRosters.forEach((roster) => {
			if (roster.isGoalie) {
				wasGoalie = wasGoalie + 1
			}
			if (!roster.isGoalie) {
				wasOut = wasOut + 1
			}
		})
		//
		console.log('wasGoalie: ' + wasGoalie)
		console.log('wasOut: ' + wasOut)
		//
		//
		if (wasGoalie > 0 && wasOut === 0) {
			console.log('this player is a life-long goalie - has never played out')
			setOnlyBeenGoalie(true)
		}
		if (wasGoalie > 0 && wasOut > 0) {
			console.log('this player has been both goalie AND played out')
			setHasPlayedOutAndBeenGoalie(true)
		}
		if (wasGoalie === 0 && wasOut > 0) {
			console.log('this player has NEVER played goalie')
			setNeverPlayedGoalie(true)
		}
		setProcessDone(true)
	}
	//
	//
	//
	//
	//player-controller, getGoalieStats
	//
	/* const getGoalieStats = async (playerId) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/player/goalieStats/${playerId}`
			)

			const responseData = await response.json()

			if (!response.ok) {
				throw new Error(responseData.message)
			}
			setGoalieStatsByRosterSeason(responseData.goalieStatsByRosterSeason)
			console.log(
				'getGoalieStats responseData: ' + JSON.stringify(responseData)
			)
		} catch (err) {
			setError(err.message)
		}
		setGoalieStats(true)
	} */
	//
	//
	//
	//player-controller, getGoalieAndNonGoalieStats
	//
	/* const getGoalieAndNonGoalieStats = async (playerId) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/player/goalieAndNonGoalieStats/${playerId}`
			)

			const responseData = await response.json()

			if (!response.ok) {
				throw new Error(responseData.message)
			}
			setSeasonsWherePlayerWasGoalie(responseData.seasonsWherePlayerWasGoalie)
			setSeasonsWherePlayerWasNotGoalie(
				responseData.seasonsWherePlayerWasNotGoalie
			)
			console.log(
				'getGoalieAndNonGoalieStats responseData: ' +
					JSON.stringify(responseData)
			)
		} catch (err) {
			setError(err.message)
		}
		setGoalieAndNonGoalieStats(true)
	} */
	//
	//
	//
	//
	/* if (!goalieStats && processDone && onlyBeenGoalie) {
		console.log('ONLY ever been a goalie so we need to go get goalie stats')
		getGoalieStats(playerId)
	}
	//
	//
	//
	//
	if (
		!goalieStats &&
		!goalieAndNonGoalieStats &&
		processDone &&
		hasPlayedOutAndBeenGoalie
	) {
		console.log(
			'has played out AND been a goalie so we need to go get BOTH kind of stats'
		)
		getGoalieAndNonGoalieStats(playerId)
	} */
	//
	//
	//
	//
	//These four functions are for if a player was ONLY ever a goalie
	/* const getWins = (key) => {
		//console.log('key: ' + key)
		console.log('inside getWins ' + goalieStatsByRosterSeason[key])
		const split = goalieStatsByRosterSeason[key].split('|')
		console.log('wins: ' + split[0])
		return split[0]
	}
	const getLosses = (key) => {
		console.log('inside getLosses')
		const split = goalieStatsByRosterSeason[key].split('|')
		console.log('losses: ' + split[1])
		return split[1]
	}
	const getShutouts = (key) => {
		console.log('inside getShutouts')
		const split = goalieStatsByRosterSeason[key].split('|')
		console.log('shutouts: ' + split[2])
		return split[2]
	}
	const getGAA = (key) => {
		console.log('inside getGAA')
		const split = goalieStatsByRosterSeason[key].split('|')
		console.log('gaa: ' + split[3])
		return Math.round(split[3] * 100) / 100
	}
	const getSavePercentage = (key) => {
		console.log('inside getSavePercentage')
		const split = goalieStatsByRosterSeason[key].split('|')
		console.log('save percentage: ' + split[4])
		return Math.round(split[4] * 100) / 100
	} */
	//
	//
	//
	//These functions are for if a player has been both a goalie and NON goalie
	/* const getYear = (key) => {
		//console.log('key: ' + key)
		console.log('inside getYear ' + seasonsWherePlayerWasGoalie[key])
		const split = seasonsWherePlayerWasGoalie[key].split('|')
		console.log('year: ' + split[0])
		return split[0]
	}
	const getTeamName = (key) => {
		//console.log('key: ' + key)
		console.log('inside getTeamName ' + seasonsWherePlayerWasGoalie[key])
		const split = seasonsWherePlayerWasGoalie[key].split('|')
		console.log('teamName: ' + split[1])
		return split[1]
	}
	const getWins2 = (key) => {
		//console.log('key: ' + key)
		console.log('inside getWins2 ' + seasonsWherePlayerWasGoalie[key])
		const split = seasonsWherePlayerWasGoalie[key].split('|')
		console.log('wins2: ' + split[2])
		return split[2]
	}
	const getLosses2 = (key) => {
		console.log('inside getLosses2')
		const split = seasonsWherePlayerWasGoalie[key].split('|')
		console.log('losses2: ' + split[3])
		return split[3]
	}
	const getShutouts2 = (key) => {
		console.log('inside getShutouts2')
		const split = seasonsWherePlayerWasGoalie[key].split('|')
		console.log('shutouts2: ' + split[4])
		return split[4]
	}
	const getGAA2 = (key) => {
		console.log('inside getGAA2')
		const split = seasonsWherePlayerWasGoalie[key].split('|')
		console.log('gaa2: ' + split[5])
		return Math.round(split[5] * 100) / 100
	}
	const getSavePercentage2 = (key) => {
		console.log('inside getSavePercentage2')
		const split = seasonsWherePlayerWasGoalie[key].split('|')
		console.log('savePercentage2: ' + split[6])
		return Math.round(split[6] * 100) / 100
	} */
	//
	//
	//
	//
	//
	//
	return (
		<div style={{ overflowX: 'hidden' }}>
			<React.Fragment>
				<StatsAndStandingsHome />
				<ErrorModal error={error} onClear={errorHandler} />
				{isLoading && (
					<div className='center' style={{ marginTop: '7rem' }}>
						<LoadingSpinner />
					</div>
				)}
				{!isLoading && loadedRosters && (
					<div className='Roster' style={{ marginTop: '6rem' }}>
						<div>
							<img src={teamLogo} className='teamLogo' />
						</div>
						{photo && (
							<div className='photoDiv'>
								<img
									src={`${process.env.REACT_APP_AWS_BUCKET}/${photo}`}
									//style={{ width: '9%' }}
									className='playerPhoto'
								/>
							</div>
						)}
						<h1>
							#{number} - {firstName} {middleInitial} {lastName}
						</h1>
						<h3 className='h3Center'>Player History</h3>
						{onlyBeenGoalie && (
							<table id='roster'>
								<thead>
									<tr>
										<th>Team</th>
										<th>Year</th>
										<th>#</th>
										<th>WIns</th>
										<th>Losses</th>
										<th>Ties</th>
										<th>Shutouts</th>
										<th>GAA</th>
										{/* <th>Save %</th> */}
									</tr>
								</thead>
								<tbody>
									{loadedRosters.map((team, key) => {
										{
											return (
												<tr key={key}>
													<td>{team.teamName}</td>
													<td>{team.year}</td>
													<td>{team.number}</td>
													<td>{team.wins}</td>
													<td>{team.losses}</td>
													<td>{team.ties}</td>
													<td>{team.shutouts}</td>
													<td>{}</td>
													{/* <td>{getSavePercentage(key)}</td> */}
												</tr>
											)
										}
									})}
								</tbody>
							</table>
						)}
						{neverPlayedGoalie && (
							<table id='roster'>
								<thead>
									<tr>
										<th>Team</th>
										<th>Year</th>
										<th>#</th>
										<th>G</th>
										<th>A</th>
										<th>Total</th>
										<th>PIM</th>
									</tr>
								</thead>
								<tbody>
									{loadedRosters.map((team, key) => {
										{
											return (
												<tr key={key}>
													<td>{team.teamName}</td>
													<td>{team.year}</td>
													<td>{team.number}</td>
													<td>{team.goals}</td>
													<td>{team.assists}</td>
													<td>{team.goals + team.assists}</td>
													<td>{team.pim}</td>
												</tr>
											)
										}
									})}
								</tbody>
							</table>
						)}
						{hasPlayedOutAndBeenGoalie && (
							<React.Fragment>
								<table id='roster'>
									<thead>
										<tr>
											<th>Team</th>
											<th>Year</th>
											<th>#</th>
											<th>G</th>
											<th>A</th>
											<th>Total</th>
											<th>PIM</th>
											<th>Wins</th>
											<th>Losses</th>
											<th>Ties</th>
											<th>Shutouts</th>
										</tr>
									</thead>
									<tbody>
										{loadedRosters.map((team, key) => {
											return (
												<tr key={key}>
													<td>{team.teamName}</td>
													<td>{team.year}</td>
													<td>{team.number}</td>
													<td>{team.goals}</td>
													<td>{team.assists}</td>
													<td>{team.goals + team.assists}</td>
													<td>{team.pim}</td>
													<td>{team.wins}</td>
													<td>{team.losses}</td>
													<td>{team.ties}</td>
													<td>{team.shutouts}</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</React.Fragment>
						)}
						{/* //
						//
						//
						//    Videos
						//
						//
						// */}
						{!isLoading && listOfVideos && (
							<div className='section pt-5 playerVideos'>
								<div className=''>
									<div className='row'>
										<div className='col text-center mb-0'>
											<h1 className='text-sh-dark display-2'>Check it out!</h1>
										</div>
									</div>
									<div className='row'>
										<div className='col-lg-3 col-sm-3'></div>
										<div className='col-lg-6 col-sm-6'>
											{listOfVideos.map((video, key) => {
												return (
													<div>
														<h1 style={{ color: 'red' }}>{video.videoTitle}</h1>

														<p
															className='caption'
															style={{
																textAlign: 'center',
																color: 'black',
															}}
														>
															{video.videoCaption}
														</p>
														{
															<YouTube
																style={{ paddingBottom: '3rem' }}
																videoId={ids[key]}
															/>
														}
													</div>
												)
											})}
										</div>
										<div className='col-lg-3 col-sm-3'></div>
									</div>
								</div>
							</div>
						)}
					</div>
				)}
				{!listOfVideos && (
					<div style={{ marginTop: '10rem' }}>
						<Footer />
					</div>
				)}
				{listOfVideos && (
					<div style={{ marginTop: '7rem' }}>
						<Footer />
					</div>
				)}
			</React.Fragment>
		</div>
	)
}

export default PlayerHistory
