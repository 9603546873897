import React, { useState, useCallback, useEffect, Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner'
import Home from './shared/components/Navigation/Home.js'
import Teams from './teams/pages/Teams.js'
import RosterAndSchedulePage from './players/components/RosterAndSchedulePage.js'
//import Leaders from './leaders/pages/Leaders.js'
import StatsAndStandingsHome from './shared/components/Navigation/StatsAndStandingsHome.js'
import SchedulesHome from './shared/components/Navigation/SchedulesHome.js'
import Standings from './standings/pages/Standings.js'
import AdminPage from './admin/pages/AdminPage.js'
import { AuthContext } from './shared/context/auth-context'
import LoginPage from './admin/pages/LoginPage.js'
import AdminTeamList from './admin/components/AdminTeamList.js'
import AdminVenueList from './admin/components/AdminVenueList.js'
import AdminFAQList from './admin/components/AdminFAQList.js'
import AdminVideoList from './admin/components/AdminVideoList.js'
import AdminNewsList from './admin/components/AdminNewsList.js'
//import AdminRegistration from './admin/components/AdminRegistration'
import AdminPlayersOnTeamList from './admin/components/AdminPlayersOnTeamList.js'
import AdminArchivedLeagueList from './admin/components/AdminArchivedLeagueList.js'
import PlayerHistory from './players/components/PlayerHistory.js'
import TeamHistoryHome from './players/components/TeamHistoryHome'
import AdminAllPlayersInSystemList from './admin/components/AdminAllPlayersInSystemList.js'
import AdminMasterSchedule from './admin/components/AdminMasterSchedule.js'
import LeagueSchedule from './shared/schedule/LeagueSchedule.js'
import TeamSchedule from './shared/schedule/TeamSchedule.js'
import VenueSchedule from './shared/schedule/VenueSchedule'
import AdminGameResults from './admin/components/AdminGameResults.js'
import AdminGameResultsPlayoff from './admin/components/AdminGameResultsPlayoff.js'
import AdminGameResultsChampionship from './admin/components/AdminGameResultsChampionship'
import TeamSchedulesHome from './shared/components/Navigation/TeamSchedulesHome'
import AdminTryoutResultsList from './admin/components/AdminTryoutResultsList.js'
//import AdminVenueSchedule from './admin/components/AdminVenueSchedule'
//import AdminLeagueSchedule from './admin/components/AdminLeagueSchedule'
import PreviousLeagues from './league/pages/PreviousLeagues'
import GameSummaryHome from './shared/components/Navigation/GameSummaryHome'
//import RegisterHome from './registration/pages/RegisterHome'
//import AdminRegistrationListOfNames from './admin/components/AdminRegistrationListOfNames'
import PlayerPhotoForm from './players/pages/PlayerPhotoForm'
import FAQs from './shared/components/Navigation/FAQs.js'
import Info from './shared/components/Navigation/Info.js'
import RostersHome from './players/components/RostersHome.js'
import AdminHowTo from './admin/components/AdminHowTo.js'
import AdminPhotoList from './admin/components/AdminPhotoList.js'
import HomePageCarouselPhotoForm from './players/pages/HomePageCarouselPhotoForm.js'
//
// lazy loading...
const NewTeamForm = React.lazy(() => import('./teams/pages/NewTeamForm.js'))
const NewLeagueForm = React.lazy(() =>
	import('./league/pages/NewLeagueForm.js')
)
const NewVenueForm = React.lazy(() =>
	import('./shared/schedule/NewVenueForm.js')
)
const NewFAQForm = React.lazy(() => import('./shared/schedule/NewFAQForm.js'))
const NewVideoForm = React.lazy(() =>
	import('./shared/schedule/NewVideoForm.js')
)
const NewNewsForm = React.lazy(() => import('./shared/schedule/NewNewsForm.js'))

const NewTryoutResultsForm = React.lazy(() =>
	import('./shared/schedule/NewTryoutResultsForm.js')
)

const NewPlayerOnTeamForm = React.lazy(() =>
	import('./players/pages/NewPlayerOnTeamForm.js')
)
const UpdateLeagueForm = React.lazy(() =>
	import('./league/pages/UpdateLeagueForm.js')
)
const UpdateVenueForm = React.lazy(() =>
	import('./shared/schedule/UpdateVenueForm.js')
)
const UpdateFAQForm = React.lazy(() =>
	import('./shared/schedule/UpdateFAQForm.js')
)
/* const UpdateRegistrantForm = React.lazy(() =>
	import('./registration/pages/UpdateRegistrantForm')
) */
const UpdateVideoForm = React.lazy(() =>
	import('./shared/schedule/UpdateVideoForm.js')
)
const UpdateNewsForm = React.lazy(() =>
	import('./shared/schedule/UpdateNewsForm.js')
)
const UpdateTryoutResultsForm = React.lazy(() =>
	import('./shared/schedule/UpdateTryoutResultsForm.js')
)
/* const UpdateRegistrationLeagueForm = React.lazy(() =>
	import('./registration/pages/UpdateRegistrationLeagueForm')
) */
const UpdateTeamForm = React.lazy(() =>
	import('./teams/pages/UpdateTeamForm.js')
)
const UpdatePlayerForm = React.lazy(() =>
	import('./players/pages/UpdatePlayerForm.js')
)
const AddGamesForm = React.lazy(() => import('./admin/games/AddGamesForm.js'))
const BrandNewPlayerForm = React.lazy(() =>
	import('./players/pages/BrandNewPlayerForm.js')
)
const ChangePlayerNumberForm = React.lazy(() =>
	import('./players/pages/ChangePlayerNumberForm.js')
)
const AddEventsForm = React.lazy(() => import('./admin/games/AddEventsForm.js'))
const UpdateGameForm = React.lazy(() =>
	import('./admin/games/UpdateGameForm.js')
)
const UpdateEventForm = React.lazy(() =>
	import('./admin/games/UpdateEventForm.js')
)

let logoutTimer

function App() {
	//Matt:  all this authorization logic stuff, Max ended up putting in a different
	//file called auth-hook.js.  This was optional.  I'm not doing that.  I'm just
	//keeping all that logic in here.
	const [token, setToken] = useState()
	const [tokenExpirationDate, setTokenExpirationDate] = useState()
	const [isLoggedIn, setIsLoggedIn] = useState(false)

	//let navigate = useNavigate()

	//should log the user out after one hour
	const login = useCallback((token, expirationDate) => {
		setIsLoggedIn(true)
		setToken(token)
		const tokenExpirationDate =
			expirationDate || new Date(new Date().getTime() + 5000 * 60 * 60) //1000*60*60
		setTokenExpirationDate(tokenExpirationDate)
		localStorage.setItem(
			'userData',
			JSON.stringify({
				token: token,
				expiration: tokenExpirationDate.toISOString(),
			})
		)
	}, [])

	const logout = useCallback(() => {
		console.log('inside logout within App.js')
		setIsLoggedIn(false)
		setToken(null)
		setTokenExpirationDate(null)
		localStorage.removeItem('userData')
	}, [])

	//dependency for this useEffect is the token.  If the token changes, then
	//we want to work with our timer
	useEffect(() => {
		if (token && tokenExpirationDate) {
			const remainingTime = tokenExpirationDate.getTime() - new Date().getTime()
			logoutTimer = setTimeout(logout, remainingTime)
		} else {
			//if we have NO token or no tokenExpirationDate
			clearTimeout(logoutTimer)
		}
	}, [token, logout, tokenExpirationDate])
	//
	//
	//
	//after render, we go in here to check localStorage
	useEffect(() => {
		const storedData = JSON.parse(localStorage.getItem('userData'))
		if (
			storedData &&
			storedData.token &&
			new Date(storedData.expiration) > new Date()
		) {
			login(storedData.token, new Date(storedData.expiration))
		}
	}, [login])

	let routes

	if (isLoggedIn) {
		routes = (
			<React.Fragment>
				<Routes>
					<Route exact path='/' element={<Home />}></Route>
					<Route
						exact
						path='/admin/team/current'
						element={<AdminPage />}
					></Route>
					<Route
						exact
						path='/statsandstandings'
						element={<RosterAndSchedulePage />}
					></Route>
					<Route exact path='/faq' element={<FAQs />}></Route>
					<Route exact path='/info' element={<Info />}></Route>
					<Route
						exact
						path='/statsandstandings/:teamId'
						element={<RosterAndSchedulePage />}
					></Route>
					<Route
						exact
						path='/gameSummary/:gameId'
						element={<GameSummaryHome />}
					></Route>
					<Route exact path='/schedule' element={<SchedulesHome />}></Route>
					<Route
						exact
						path='/schedule/:teamId'
						element={<SchedulesHome />}
					></Route>
					<Route exact path='/league/:leagueId' element={<Teams />}></Route>
					<Route exact path='/admin/howto' element={<AdminHowTo />}></Route>
					{/* <Route
						exact
						path='/league/:leagueId/scoringLeaders'
						element={<Leaders />}
					></Route> */}
					<Route exact path='/history' element={<TeamHistoryHome />}></Route>
					<Route exact path='/rosters' element={<RostersHome />}></Route>
					<Route
						exact
						path='/league/:leagueId/standings'
						element={<Standings />}
					></Route>
					<Route
						exact
						path='/league/previousLeagues'
						element={<PreviousLeagues />}
					></Route>
					<Route
						exact
						path='/league/schedule/:leagueId'
						element={<LeagueSchedule />}
					></Route>
					{/* <Route
						exact
						path='/admin/:venueId/schedule'
						element={<AdminVenueSchedule />}
					></Route> */}
					<Route
						exact
						path='/admin/:playerId/playerPhoto'
						element={<PlayerPhotoForm />}
					></Route>
					<Route
						exact
						path='/admin/carouselPhoto'
						element={<HomePageCarouselPhotoForm />}
					></Route>
					{/* <Route
						exact
						path='/schedule/admin/:leagueId'
						element={<AdminLeagueSchedule />}
					></Route> */}
					<Route
						exact
						path='/league/:venueId/schedule'
						element={<VenueSchedule />}
					></Route>
					<Route
						exact
						path='/league/schedule/:leagueName/:session/:year/:teamName'
						element={<TeamSchedulesHome />}
					></Route>
					<Route
						exact
						path='/admin/team/new'
						element={<NewLeagueForm />}
					></Route>
					<Route
						exact
						path='/admin/venue/new'
						element={<NewVenueForm />}
					></Route>
					<Route exact path='/admin/faq/new' element={<NewFAQForm />}></Route>
					<Route
						exact
						path='/admin/video/new'
						element={<NewVideoForm />}
					></Route>
					<Route exact path='/admin/news/new' element={<NewNewsForm />}></Route>
					<Route
						exact
						path='/admin/tryout/results'
						element={<NewTryoutResultsForm />}
					></Route>
					{/* <Route
						exact
						path='/register/registrationLeague/new'
						element={<NewRegistrationLeagueForm />}
					></Route> */}
					<Route
						exact
						path='/admin/teams/archive'
						element={<AdminArchivedLeagueList />}
					></Route>
					<Route
						exact
						path='/admin/updateTeam/:teamId'
						element={<UpdateLeagueForm />}
					></Route>
					<Route
						exact
						path='/admin/updateVenue/:venueId'
						element={<UpdateVenueForm />}
					></Route>
					<Route
						exact
						path='/admin/updateFaq/:faqId'
						element={<UpdateFAQForm />}
					></Route>
					<Route
						exact
						path='/admin/updateVideo/:videoId'
						element={<UpdateVideoForm />}
					></Route>
					<Route
						exact
						path='/admin/updateNews/:newsId'
						element={<UpdateNewsForm />}
					></Route>
					<Route
						exact
						path='/admin/updateTryoutResults/:tryoutResultsId'
						element={<UpdateTryoutResultsForm />}
					></Route>
					{/* <Route
						exact
						path='/admin/updateRegistrant/:modalFor/:registrantId'
						element={<UpdateRegistrantForm />}
					></Route> */}
					{/* <Route
						exact
						path='/registration/updateRegistrationLeague/:regLeagueId'
						element={<UpdateRegistrationLeagueForm />}
					></Route> */}
					<Route
						exact
						path='/admin/:leagueName/:session/:year/newTeam'
						element={<NewTeamForm />}
					></Route>
					<Route
						exact
						path='/admin/:teamName/:year/newPlayer'
						element={<NewPlayerOnTeamForm />}
					></Route>
					<Route
						exact
						path='/admin/:leagueName/:session/:year/updateTeam/:teamId'
						element={<UpdateTeamForm />}
					></Route>
					<Route
						exact
						path='/admin/:leagueName/:session/:year/updateTeamWithDivision/:teamId'
						element={<UpdateTeamForm />}
					></Route>
					<Route
						exact
						path='/admin/:teamName/:year/:playerId/changeNumber'
						element={<ChangePlayerNumberForm />}
					></Route>
					<Route
						exact
						path='/admin/:teamName/:year/roster'
						element={<AdminTeamList />}
					></Route>
					<Route
						exact
						path='/admin/:leagueName/:divisionName/:session/:year/teams'
						element={<AdminTeamList />}
					></Route>
					<Route
						exact
						path='/admin/masterSchedule'
						element={<AdminMasterSchedule />}
					></Route>
					<Route
						exact
						path='/admin/venues'
						element={<AdminVenueList />}
					></Route>
					<Route
						exact
						path='/admin/photos'
						element={<AdminPhotoList />}
					></Route>
					<Route exact path='/admin/faqs' element={<AdminFAQList />}></Route>
					<Route
						exact
						path='/admin/allVideos'
						element={<AdminVideoList />}
					></Route>
					<Route exact path='/admin/news' element={<AdminNewsList />}></Route>
					<Route
						exact
						path='/admin/tryoutresults'
						element={<AdminTryoutResultsList />}
					></Route>
					{/* <Route
						exact
						path='/admin/registration'
						element={<AdminRegistration />}
					></Route> */}
					<Route
						exact
						path='/admin/:teamName/:year/players'
						element={<AdminPlayersOnTeamList />}
					></Route>
					<Route
						exact
						path='/admin/:leagueName/:divisionName/:session/:year/:teamName/players'
						element={<AdminPlayersOnTeamList />}
					></Route>
					<Route
						exact
						path='/admin/newPlayer'
						element={<BrandNewPlayerForm />}
					></Route>
					<Route
						exact
						path='/admin/getAllPlayers'
						element={<AdminAllPlayersInSystemList />}
					></Route>
					<Route
						exact
						path='/admin/updatePlayer/:playerId'
						element={<UpdatePlayerForm />}
					></Route>
					<Route
						exact
						path='/admin/updateGame/:gameId'
						element={<UpdateGameForm />}
					></Route>
					<Route
						exact
						path='/admin/updateEvent/:gameId'
						element={<UpdateEventForm />}
					></Route>
					<Route
						exact
						path='/admin/:teamName/:year/addPlayers'
						element={<NewPlayerOnTeamForm />}
					></Route>
					<Route exact path='/games/new' element={<AddGamesForm />}></Route>
					<Route
						exact
						path='/admin/events/new'
						element={<AddEventsForm />}
					></Route>
					<Route
						exact
						path='/admin/gameResults/:gameId'
						element={<AdminGameResults />}
					></Route>
					<Route
						exact
						path='/admin/playoffGameResults/:gameId'
						element={<AdminGameResultsPlayoff />}
					></Route>
					<Route
						exact
						path='/admin/championshipGameResults/:gameId'
						element={<AdminGameResultsChampionship />}
					></Route>
					{/* <Route
						exact
						path='/admin/:leagueNameAndDesc'
						element={<AdminRegistrationListOfNames />}
					></Route> */}
					<Route
						exact
						path='/:playerId/history'
						element={<PlayerHistory />}
					></Route>
					{/* <Route exact path='/register' element={<RegisterHome />}></Route> */}
				</Routes>
			</React.Fragment>
		)
	} else {
		routes = (
			<React.Fragment>
				<Routes>
					<Route exact path='/' element={<Home />}></Route>
					<Route exact path='/admin/login' element={<LoginPage />}></Route>
					<Route
						exact
						path='/statsandstandings'
						element={<RosterAndSchedulePage />}
					></Route>
					<Route
						exact
						path='/statsandstandings/:teamId'
						element={<RosterAndSchedulePage />}
					></Route>
					<Route exact path='/history' element={<TeamHistoryHome />}></Route>
					<Route exact path='/rosters' element={<RostersHome />}></Route>
					<Route
						exact
						path='/:playerId/history'
						element={<PlayerHistory />}
					></Route>
					<Route exact path='/faq' element={<FAQs />}></Route>
					<Route exact path='/info' element={<Info />}></Route>
					<Route
						exact
						path='/gameSummary/:gameId'
						element={<GameSummaryHome />}
					></Route>
					<Route exact path='/schedule' element={<SchedulesHome />}></Route>
					<Route
						exact
						path='/schedule/:teamId'
						element={<SchedulesHome />}
					></Route>
					<Route exact path='/league/:leagueId' element={<Teams />}></Route>
					<Route
						exact
						path='/league/previousLeagues'
						element={<PreviousLeagues />}
					></Route>
					{/* <Route
						exact
						path='/league/:leagueId/scoringLeaders'
						element={<Leaders />}
					></Route> */}
					<Route
						exact
						path='/league/leagues/:leagueId/standings'
						element={<Standings />}
					></Route>
					<Route
						exact
						path='/league/schedule/:leagueId'
						element={<LeagueSchedule />}
					></Route>
					<Route
						exact
						path='/league/:venueId/schedule'
						element={<VenueSchedule />}
					></Route>
					<Route
						exact
						path='/league/schedule/:leagueName/:session/:year/:teamName'
						element={<TeamSchedule />}
					></Route>
					{/* <Route exact path='/register' element={<RegisterHome />}></Route> */}
				</Routes>
			</React.Fragment>
		)
	}

	//not sure if I want to include a redirect here?  Watch end of Max video 40 if i decide to
	return (
		<AuthContext.Provider
			value={{
				isLoggedIn: !!token,
				token: token,
				login: login,
				logout: logout,
			}}
		>
			<Router>
				{/* <Routes> */}
				<Suspense
					fallback={
						<div className='center'>
							<LoadingSpinner />
						</div>
					}
				>
					{routes}
				</Suspense>
				{/* </Routes> */}
			</Router>
		</AuthContext.Provider>
	)
}

export default App
