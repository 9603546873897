import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import './Standings.css'
import StatsAndStandingsHome from '../../shared/components/Navigation/StatsAndStandingsHome'
import StatsAndStandingsHomeNavigation from '../../shared/components/Navigation/StatsAndStandingsHomeNavigation'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'

const Standings = () => {
	const [error, setError] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [loadedStandings, setLoadedStandings] = useState()
	const [leagueName, setLeagueName] = useState()
	const [divisionName, setDivisionName] = useState()
	const [session, setSession] = useState()
	const [year, setYear] = useState()

	const leagueId = useParams().leagueId

	//call to getStandings in the league-controller
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/league/leagues/${leagueId}/standings`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				if (responseData.listOfTeams[0].divisionName) {
					setDivisionName(responseData.listOfTeams[0].divisionName)
				} else {
					setDivisionName('')
				}

				setLoadedStandings(responseData.listOfTeams)
				setLeagueName(responseData.leagueName)
				setSession(responseData.session)
				setYear(responseData.year)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [leagueId, leagueName, year])

	//
	//
	const errorHandler = () => {
		setError(null)
	}

	//Here, we're sorting by points
	//Tiebreaker should be:  Points > Won > GD > GF > GA > Pct
	if (loadedStandings) {
		loadedStandings.forEach((team) => {
			team.points =
				team.wins * 2 + team.overtimeLosses + team.shootoutLosses + team.ties
		})
		//loadedStandings.sort((a, b) => (a.wins * 2 < b.wins * 2 ? 1 : -1))
		loadedStandings.sort((a, b) => (a.points < b.points ? 1 : -1))
		loadedStandings.sort((a, b) =>
			a.points === b.points && a.wins > b.wins ? 1 : -1
		)
		loadedStandings.sort((a, b) =>
			a.points === b.points &&
			a.wins === b.wins &&
			a.goalsFor - a.goalsAgainst < b.goalsFor - b.goalsAgainst
				? 1
				: -1
		)
		loadedStandings.sort((a, b) =>
			a.points === b.points &&
			a.wins === b.wins &&
			a.goalsFor - a.goalsAgainst === b.goalsFor - b.goalsAgainst &&
			a.goalsFor > b.goalsFor
				? -1
				: 1
		)
	}

	return (
		<React.Fragment>
			<StatsAndStandingsHome />
			<StatsAndStandingsHomeNavigation leagueId={leagueId} />
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && loadedStandings && (
				<div className='Roster'>
					<h1>League Standings</h1>
					<h1>
						{leagueName} {divisionName && ' - ' + divisionName + ' Division'}
					</h1>
					<h2>
						{session} {year}
					</h2>
					<table id='roster' cellSpacing={'0'} cellPadding={'0'}>
						<thead>
							<tr>
								<th>Team</th>
								<th>GP</th>
								<th>W</th>
								<th>L</th>
								<th>T</th>
								<th>OTL</th>
								<th>SOL</th>
								<th>GF</th>
								<th>GA</th>
								<th>GD</th>
								<th>Points</th>
								<th>%</th>
							</tr>
						</thead>
						<tbody>
							{loadedStandings.map((team, key) => {
								return (
									<tr key={key}>
										<td className='tableDataTeamName'>
											<Link
												style={{
													textDecoration: 'none',
													color: 'black',
													fontWeight: 600,
												}}
												to={`${session}/${year}/${team.teamName}`}
											>
												{team.teamName}
											</Link>
										</td>
										<td>
											{team.wins +
												team.losses +
												team.ties +
												team.shootoutLosses +
												team.overtimeLosses}
										</td>
										<td>{team.wins}</td>
										<td>{team.losses}</td>
										<td>{team.ties}</td>
										<td>{team.overtimeLosses}</td>
										<td>{team.shootoutLosses}</td>
										<td>{team.goalsFor}</td>
										<td>{team.goalsAgainst}</td>
										<td>{team.goalsFor - team.goalsAgainst}</td>
										{/* <td>
											{team.wins * 2 +
												team.overtimeLosses +
												team.shootoutLosses}
										</td> */}
										<td>{team.points}</td>
										<td>
											{Number(
												(team.wins + team.ties * 0.5) /
													(team.wins +
														team.losses +
														team.overtimeLosses +
														team.shootoutLosses +
														team.ties)
												/* team.wins &&
													team.points /
														(team.wins * 2 +
															team.losses * 2 +
															team.overtimeLosses * 2 +
															team.shootoutLosses * 2 +
															team.ties * 2) */
											).toFixed(3)}
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			)}
			{!isLoading && !loadedStandings && (
				<div className='Standings'>
					<h1>
						{leagueName} {divisionName && divisionName} - League Standings
					</h1>
					<h2>
						{session} {year}
					</h2>
					<table id='players' cellSpacing={'0'} cellPadding={'0'}>
						<thead>
							<tr>
								<th>Team</th>
								<th>GP</th>
								<th>W</th>
								<th>L</th>
								<th>T</th>
								<th>OTL</th>
								<th>SOL</th>
								<th>GF</th>
								<th>GA</th>
								<th>Points</th>
							</tr>
						</thead>
					</table>
				</div>
			)}
		</React.Fragment>
	)
}

export default Standings
