import React, { useEffect, useState } from 'react'
import './AdminTeamList.css'
import { NavLink } from 'react-router-dom'
import AdminNavigation from './AdminNavigation'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'

const AdminHowTo = () => {
	const [error, setError] = useState()

	const errorHandler = () => {
		setError(null)
	}

	return (
		<React.Fragment>
			<AdminNavigation />
			<div>
				<div className='Teams' style={{ marginBottom: '4rem' }}>
					<AdminNavigation />
					<h1>How-To Videos</h1>
					<p style={{ textAlign: 'center', marginBottom: '1rem' }}>
						The videos on this page should help you get started with setting up
						and using your site.
					</p>
					<p style={{ textAlign: 'center', marginBottom: '1rem' }}>
						For any additional questions or concerns, you can always email me at
						matt@shireystatsandstandings.com
					</p>
					<p style={{ textAlign: 'center', marginBottom: '1rem' }}>
						Or, feel free to text me at 724-875-8661.
					</p>
					<p style={{ textAlign: 'center', marginBottom: '1rem' }}>
						Thank you!
					</p>
				</div>
				{/* //
        //
        //   Video One
        //
        // */}
				<div style={{ display: '-webkit-box' }}>
					<a
						href='https://www.youtube.com/watch?v=PGzpCUumefE'
						target='_blank'
						rel='noreferror'
						className='infoLink'
					>
						Video 1: Getting Started
					</a>
				</div>
				<h5 style={{ marginBottom: '2.5rem' }}>
					Log in, add venues, add a new team, add players to the system, add
					players to a team.
				</h5>
				{/* //
        //
        //   Video Two
        //
        // */}
				<div style={{ display: '-webkit-box' }}>
					<a
						href='https://www.youtube.com/watch?v=VoYV4mZmDE4'
						target='_blank'
						rel='noreferror'
						className='infoLink'
					>
						Video 2: Adding Games and Events
					</a>
				</div>
				<h5 style={{ marginBottom: '2.5rem' }}>
					Add games, add events, edit games or events, view the schedule.
				</h5>
				{/* //
        //
        //   Video Three
        //
        // */}
				<div style={{ display: '-webkit-box' }}>
					<a
						href='https://www.youtube.com/watch?v=6Gjvur7SOIM'
						target='_blank'
						rel='noreferror'
						className='infoLink'
						style={{ display: '-webkit-box' }}
					>
						Video 3: Entering Game Results
					</a>
				</div>
				<h5 style={{ marginBottom: '2.5rem' }}>
					Enter game results, including score, player stats, goalie stats, and
					penalties.
				</h5>
				{/* //
        //
        //   Video Four
        //
        // */}
				{/* 	<div style={{ display: '-webkit-box' }}>
					<a
						href='https://www.youtube.com/watch?v=JJuEDuB_nC0'
						target='_blank'
						rel='noreferror'
						className='infoLink'
						style={{ display: '-webkit-box' }}
					>
						Video 4: Upload Videos, Add News, Add Profile Pictures
					</a>
				</div>
				<h5 style={{ marginBottom: '2.5rem' }}>
					How to link a YouTube video to your site, add profile pics for
					individual players, and add news segments to the home page.
				</h5> */}
				{/* //
        //
        //   Video Four
        //
        // */}
				<div style={{ display: '-webkit-box' }}>
					<a
						href='https://www.youtube.com/watch?v=c8lV_wR4PpU'
						target='_blank'
						rel='noreferror'
						className='infoLink'
						style={{ display: '-webkit-box' }}
					>
						Video 4: Archiving Teams, View Player History
					</a>
				</div>
				<h5 style={{ marginBottom: '2.5rem' }}>
					What does archiving mean, and why do we archive old teams.
				</h5>
				{/* //
        //
        //   Video Five
        //
        // */}
				<div style={{ display: '-webkit-box' }}>
					<a
						href='https://www.youtube.com/watch?v=fZUt3Uv6ogU'
						target='_blank'
						rel='noreferror'
						className='infoLink'
						style={{ display: '-webkit-box' }}
					>
						Video 5: Entering Tryout Results
					</a>
				</div>
				<h5 style={{ marginBottom: '2.5rem' }}>
					Enter tryout results for next years teams.
				</h5>
				{/* //
        //
        //   Video Six
        //
        // */}
				<div style={{ display: '-webkit-box' }}>
					<a
						href='https://www.youtube.com/watch?v=mflB6bOVQVQ'
						target='_blank'
						rel='noreferror'
						className='infoLink'
						style={{ display: '-webkit-box' }}
					>
						Video 6: FAQ's
					</a>
				</div>
				<h5 style={{ marginBottom: '2.5rem' }}>
					Create an FAQ list to appear on the home page.
				</h5>
				{/* //
        //
        //   Video Seven
        //
        // */}
				<div style={{ display: '-webkit-box' }}>
					<a
						href='https://www.youtube.com/watch?v=cXl3hoo1kwI'
						target='_blank'
						rel='noreferror'
						className='infoLink'
						style={{ display: '-webkit-box' }}
					>
						Video 7: Home Page Carousel Photos
					</a>
				</div>
				<h5 style={{ marginBottom: '2.5rem' }}>
					Add your own photos to the Carousel on the home page.
				</h5>
				{/* //
        //
        //   Video Eight
        //
        // */}
				<div style={{ display: '-webkit-box' }}>
					<a
						href='https://www.youtube.com/watch?v=HXsQS4lbDoI'
						target='_blank'
						rel='noreferror'
						className='infoLink'
						style={{ display: '-webkit-box' }}
					>
						Video 8: Player Photos
					</a>
				</div>
				<h5 style={{ marginBottom: '5rem' }}>
					Add an action photo or a headshot pic for a players individual Player
					History page.
				</h5>
			</div>
		</React.Fragment>
	)
}

export default AdminHowTo
