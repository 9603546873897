import React from 'react'
import StatsAndStandingsHomeNavigation from './StatsAndStandingsHomeNavigation'

const StatsAndStandingsHome = (props) => {
	console.log('inside StatsAndStandingsHome ' + JSON.stringify(props))
	let teamId = props.teamId
	return (
		<div>
			<StatsAndStandingsHomeNavigation teamId={teamId} />
		</div>
	)
}

export default StatsAndStandingsHome
