import React, { useState, useEffect } from 'react'
import './Home.css'
import Footer from '../UIElements/Footer'
import HomeHomeNavigation from './HomeHomeNavigation'
import LoadingSpinner from '../UIElements/LoadingSpinner'
import ErrorModal from '../UIElements/ErrorModal'
import YouTube from 'react-youtube'
import Carousel from 'react-bootstrap/Carousel'
import emailjs from 'emailjs-com'
import cranberryClassicFlyer from '../images/Cranberry_Classic_Flyer.jpg'
var getYouTubeID = require('get-youtube-id')
//

const Home = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [ids, setIds] = useState([])
	const [videoTitles, setVideoTitles] = useState([])
	const [videoCaptions, setVideoCaptions] = useState([])
	const [loadedVideos, setLoadedVideos] = useState([])
	const [loadedNews, setLoadedNews] = useState([])
	const [loadedCarouselPhotos, setLoadedCarouselPhotos] = useState([])

	//Get all videos designated for the Home Page
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/homePageVideos'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedVideos(responseData.allHomePageVideos)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			//
			//
			setIsLoading(false)
		}
		sendRequest()
	}, [])
	//
	//
	//
	//Get all Carousel photos
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/carouselPhotos'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedCarouselPhotos(responseData.allCarouselPhotos)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			//
			//
			setIsLoading(false)
		}
		sendRequest()
	}, [])
	//
	//
	loadedCarouselPhotos &&
		console.log('loadedCarouselPhotos: ' + JSON.stringify(loadedCarouselPhotos))
	//
	//
	//
	let arrayOfTitles, arrayOfIds, arrayOfCaptions
	arrayOfTitles = []
	arrayOfIds = []
	arrayOfCaptions = []
	if (loadedVideos && !isLoading && ids.length === 0) {
		loadedVideos.forEach((video) => {
			arrayOfTitles.push(video.videoTitle)
			arrayOfIds.push(getYouTubeID(video.videoURL))
			arrayOfCaptions.push(video.videoCaption)
		})
	}

	if (arrayOfTitles.length > 0) {
		setVideoTitles(arrayOfTitles)
	}
	if (arrayOfIds.length > 0) {
		setIds(arrayOfIds)
	}
	if (arrayOfCaptions.length > 0) {
		setVideoCaptions(arrayOfCaptions)
	}
	//
	//
	//
	//
	//
	//
	//
	//Get all News
	useEffect(() => {
		const sendRequest2 = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/news'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedNews(responseData.allNews)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			//
			//
			setIsLoading(false)
		}

		sendRequest2()
	}, [])
	//
	//
	//
	//
	function sendEmail(e) {
		e.preventDefault()

		emailjs
			.sendForm(
				'service_1mgo6yi',
				'template_2ro9ssl',
				document.getElementById('emailForm'),
				'2o7wwEXC0qTBmC15O'
			)
			.then((res) => {
				console.log('res: ' + JSON.stringify(res))
				alert('Your email is on its way!')
				document.getElementById('emailForm').reset()
			})
			.catch((err) => console.log(err))
	}
	//
	//
	//
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	return (
		<div style={{ overflowX: 'hidden' }}>
			<React.Fragment>
				<ErrorModal error={error} onClear={errorHandler} />
				{isLoading && <LoadingSpinner asOverlay />}
				{!isLoading && (
					<div>
						<header className='homeHeader' style={{ borderBottom: 'solid' }}>
							<HomeHomeNavigation />
							{/* <div className='text-light text-md-right text-center banner headingWriting'>
								<h1
									className='display-4 lead headingText'
									style={{
										fontWeight: '600',
										color: '#f6c24e',
										textShadow: '2px 2px 2px #410009',
									}}
								>
									Screaming Eagles
								</h1>
								<div className='underline-white mb-4'></div>
								<h1
									className='display-4 lead subheading'
									style={{
										marginBottom: '1rem',
										fontWeight: '600',
										color: '#f6c24e',
										textShadow: '2px 2px 2px #410009',
									}}
								>
									Est. 2015
								</h1>
							</div> */}
						</header>
						<div className='section pt-5'>
							<div className=''>
								<div className='row'>
									<div className='col text-center mb-3'>
										<h1
											// className='display-4 lead headingText'
											className='display-2'
											style={{
												fontWeight: '600',
												color: '#410009',
												textShadow: '2px 2px 2px #fee09a',
											}}
										>
											News and Updates
										</h1>
									</div>
								</div>
								{/* //
								//
								//    NEWS CAROUSEL
								//
								// */}
								<div className='section'>
									<div className='row'>
										<div className='col-lg-3 col-sm-6'></div>
										{!isLoading && loadedNews && (
											<Carousel>
												{loadedNews.map((news, key) => (
													<Carousel.Item interval={7000}>
														<div>
															<h1 style={{ color: 'red' }}>
																{news.newsHeading}
															</h1>

															<p
																className='caption'
																style={{
																	textAlign: 'center',
																	color: 'black',
																}}
															>
																{news.newsSubheading}
															</p>
															<p
																className='caption'
																style={{
																	textAlign: 'center',
																	color: 'black',
																}}
															>
																{news.newsDate}
															</p>
															<p
																className='content'
																style={{
																	textAlign: 'center',
																	color: 'black',
																	marginBottom: '3rem',
																	whiteSpace: 'pre-wrap',
																}}
															>
																{news.newsContent}
															</p>
														</div>
													</Carousel.Item>
												))}
											</Carousel>
										)}
										<div className='col-lg-3 col-sm-6'></div>
									</div>
								</div>
								{/* //
								//
								//    NEWS THE OLD SCHOOL WAY
								//
								// */}
								{/* <div className='row'>
									<div className='col-lg-3 col-sm-6'></div>
									{!isLoading &&
										loadedNews &&
										loadedNews.map((news, key) => {
											return (
												<div>
													<h1 style={{ color: 'red' }}>{news.newsHeading}</h1>

													<p
														className='caption'
														style={{
															textAlign: 'center',
															color: 'black',
														}}
													>
														{news.newsSubheading}
													</p>
													<p
														className='caption'
														style={{
															textAlign: 'center',
															color: 'black',
														}}
													>
														{news.newsDate}
													</p>
													<p
														className='content'
														style={{
															textAlign: 'center',
															color: 'black',
															marginBottom: '3rem',
															whiteSpace: 'pre-wrap',
														}}
													>
														{news.newsContent}
													</p>
												</div>
											)
										})}
									<div className='col-lg-3 col-sm-6'></div>
								</div> */}
							</div>
						</div>
						{/* //
						//
						//
						//
						// */}
						<div className='section pt-5 about'>
							<div className=''>
								<div className='row'>
									<div className='col text-center mb-3'>
										<h1
											// className='display-4 lead headingText'
											className='display-2'
											style={{
												fontWeight: '600',
												color: '#410009',
												textShadow: '2px 2px 2px #fee09a',
											}}
										>
											About Us
										</h1>
									</div>
								</div>
								<div className='row'>
									{/* <div className='col-lg-3 col-sm-6'></div> */}
									<h1 className='aboutUsText'>
										Established in 2015, we are a group of volunteer board
										members, coaches, assistant coaches, team moms, awesome
										parents, and super talented kids who love hockey more than
										anything.
									</h1>
								</div>
								<div className='row'>
									<div className='col text-center mb-3'>
										<h1
											// className='display-4 lead headingText'
											className='display-2'
											style={{
												fontWeight: '600',
												color: '#410009',
												textShadow: '2px 2px 2px #fee09a',
											}}
										>
											2025 Cranberry Classic
										</h1>
										<h4 style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
											Register{' '}
											<a
												href='https://forms.gle/cwtQoTC5WMhUUb5k8'
												target='_blank'
											>
												HERE
											</a>
										</h4>
										{/* <h5 style={{ color: '#090085' }}>coming soon.....</h5> */}
										<div className='row'>
											<img
												src={cranberryClassicFlyer}
												className='img-thumbnail tournamentSchedule'
											/>
										</div>
										<p className='lead text-secondary'></p>
									</div>
								</div>
							</div>
						</div>
						{/* //
						//
						//
						//     CAROUSEL
						//
						//
						//
						// */}
						<div className='section pt-5'>
							<div className=''>
								<div className='row'>
									<div className='col text-center mb-3'>
										<h1
											className='display-2'
											style={{
												fontWeight: '600',
												color: '#f6c24e',
												textShadow: '2px 2px 2px #410009',
											}}
										>
											2024 Tournament Champions
										</h1>
									</div>
								</div>
								{/* //
								//
								// */}
								<div className='section'>
									<div className='row'>
										<div className='col-lg-3 col-sm-6'></div>
										{!isLoading && loadedCarouselPhotos && (
											<Carousel>
												{loadedCarouselPhotos.map((photo, key) => (
													<Carousel.Item interval={7000}>
														<img
															className='d-block carouselPhoto'
															src={`${process.env.REACT_APP_AWS_BUCKET}/${photo.photo}`}
															alt='Image One'
														/>
													</Carousel.Item>
												))}
											</Carousel>
										)}
										<div className='col-lg-3 col-sm-6'></div>
									</div>
								</div>
							</div>
						</div>
						{/* //
						//
						//
						//    END OF CAROUSEL
						//
						//
						//
						// */}
						<div className='section pt-5 videos'>
							<div className=''>
								<div className='row'>
									<div className='col text-center mb-3'>
										<h1
											// className='display-4 lead headingText'
											className='display-2'
											style={{
												fontWeight: '600',
												color: '#410009',
												textShadow: '2px 2px 2px #fee09a',
											}}
										>
											Watch!
										</h1>
									</div>
								</div>
								<div className='row'>
									<div className='col-lg-3 col-sm-3'></div>
									<div className='col-lg-6 col-sm-6'>
										{!isLoading &&
											loadedVideos &&
											loadedVideos.map((video, key) => {
												return (
													<div>
														<h1 style={{ color: 'red' }}>{video.videoTitle}</h1>

														<p
															className='caption'
															style={{
																textAlign: 'center',
																color: 'black',
															}}
														>
															{video.videoCaption}
														</p>
														{
															<YouTube
																style={{ paddingBottom: '3rem' }}
																videoId={ids[key]}
															/>
														}
													</div>
												)
											})}
									</div>
									<div className='col-lg-3 col-sm-3'></div>
								</div>
							</div>
						</div>
						{/* //
				//
				//
				// */}
						<div
							className='section container-gray'
							style={{ padding: '1rem' }}
						></div>
						<div>
							<div className='row'>
								<div className='col text-center mb-3'>
									<h1
										className='display-2'
										style={{ color: '#410009', fontWeight: '600' }}
									>
										Contact Us
									</h1>
								</div>
							</div>
							<div className='row justify-content-center'>
								<div className='col-lg-6 col-md-8 col-sm-10'>
									<div
										className='text-center text-secondary'
										style={{ marginBottom: '1rem' }}
									>
										<h2>Got a Question?</h2>
										<h5>Let's Hear it!</h5>
									</div>
									<form
										id='emailForm'
										className='text-muted'
										method='post'
										enctype='text/plain'
									>
										<div className='form-group contactUsInputField'>
											<label for='name'>Name</label>
											<input
												type='text'
												className='form-control'
												id='fromName'
												name='fromName'
												required
											/>
										</div>
										<div className='form-group contactUsInputField'>
											<label for='email'>Email</label>
											<input
												type='email'
												className='form-control'
												id='fromEmail'
												name='fromEmail'
												required
											/>
										</div>
										<div className='form-group contactUsInputField'>
											<label for='message'>Message</label>
											<textarea
												name='message'
												className='form-control'
												id='message'
												rows='3'
												required
											></textarea>
										</div>
										<button
											className='btn btn-block submitEmailBtn'
											type='button'
											onClick={sendEmail}
										>
											Submit Question
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				)}
				<Footer />
			</React.Fragment>
		</div>
	)
}

export default Home
