//non admin
//I've decided to do this page NOT by venue, but just a long list
import React, { useState, useEffect } from 'react'
import './FullSchedule.css'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Footer from '../components/UIElements/Footer'
import LoadingSpinner from '../components/UIElements/LoadingSpinner'
import SchedulesHomeNavigation from '../components/Navigation/SchedulesHomeNavigation'
import teamLogo from '../../shared/components/images/new_logo_gray_background.jpg'

const TeamSchedule = () => {
	console.log('inside TeamSchedule')
	//
	const incomingTeamId = useParams().teamId
	console.log('incomingTeamId: ' + incomingTeamId)
	let navigate = useNavigate()
	//
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [year, setYear] = useState()
	const [teamName, setTeamName] = useState()
	const [wins, setWins] = useState()
	const [losses, setLosses] = useState()
	const [overtimeLosses, setOvertimeLosses] = useState()
	const [shootoutLosses, setShootoutLosses] = useState()
	const [teamId, setTeamId] = useState()
	const [ties, setTies] = useState()
	const [allTeamGamesAndEvents, setAllTeamGamesAndEvents] = useState([])
	const [loadedTeams, setLoadedTeams] = useState()
	const [teamDropdownCreated, setTeamDropdownCreated] = useState(false)
	const [teamArray, setTeamArray] = useState([])
	//
	//
	//
	////Call backend to get all the current teams for our dropdowns
	//     getCurrentTeam
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/teams/current'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				//console.log('responseData: ' + JSON.stringify(responseData))
				setLoadedTeams(responseData.currentTeams)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			//
			setIsLoading(false)
		}
		sendRequest()
	}, [])
	//
	//
	//This creates an array from the loadedTeams.  It will be our 'Select a Team' dropdown:
	if (loadedTeams && !isLoading && !teamDropdownCreated) {
		const innerTeamArray = []
		loadedTeams.forEach((team) => {
			const teamDisplay = team.teamName + ' - ' + team.year
			innerTeamArray.push(teamDisplay)
		})
		//NEW  This was added for leagues that have divisions
		let uniqueArray = []
		const duplicateFilter = innerTeamArray.filter((element) => {
			if (!uniqueArray.includes(element)) {
				uniqueArray.push(element)

				return true
			}
			return false
		})
		//end NEW
		setTeamArray(duplicateFilter)
		setTeamDropdownCreated(true)
	}
	//
	//
	//
	//
	let rem
	if (allTeamGamesAndEvents.length === 0) {
		rem = '14rem'
	} else {
		rem = '8rem'
	}
	//
	//
	/* if (isLoading) {
		return (
			<div className='center' style={{ marginTop: '10rem' }}>
				<LoadingSpinner />
			</div>
		)
	} */
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	let selectedTeam
	//
	//This function is if a user selects a team from the dropdown
	//
	const handleChange = async (e) => {
		if (e.target.value !== 'Select a Team') {
			e.preventDefault()
			const selectedValue = e.target.value
			//console.log('selectedValue: ' + selectedValue)
			selectedTeam = loadedTeams[selectedValue]
			const teamId = selectedTeam._id
			console.log('teamId: ' + teamId)
			//
			//
			//Lets see if this works...
			setTeamId(teamId)
			//
			//
			//
			navigate(`/schedule/${teamId}`)
			//
			//Maybe make a backend call right here to get this teams data
			//makes a call to league-controller, getTeamSchedule3.
			setIsLoading(true)
			setAllTeamGamesAndEvents([])
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/league/${teamId}/teamSchedule`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setAllTeamGamesAndEvents(responseData.allGamesAndEventsArray)
				setTeamName(responseData.teamName)
				setYear(responseData.year)
				setWins(responseData.wins)
				setLosses(responseData.losses)
				setOvertimeLosses(responseData.overtimeLosses)
				setShootoutLosses(responseData.shootoutLosses)
				setTies(responseData.ties)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
	}
	//
	//
	//This is if theres an incomingTeamId, which means that the user was on the player stats page for
	//a team, then just clicked the 'SCHEDULE' tab at the top.  This prevents the user from having
	//to select the team again.
	//
	//
	useEffect(() => {
		const incomingTeamFunction = async (e) => {
			const teamId = incomingTeamId
			console.log('inside incomingTeamFunction: ' + teamId)
			setTeamId(teamId)
			//
			setIsLoading(true)
			setAllTeamGamesAndEvents([])
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/league/${teamId}/teamSchedule`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setAllTeamGamesAndEvents(responseData.allGamesAndEventsArray)
				setTeamName(responseData.teamName)
				setYear(responseData.year)
				setWins(responseData.wins)
				setLosses(responseData.losses)
				setOvertimeLosses(responseData.overtimeLosses)
				setShootoutLosses(responseData.shootoutLosses)
				setTies(responseData.ties)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		if (incomingTeamId) {
			incomingTeamFunction()
		}
	}, [])
	//
	//
	//
	//
	let dateBlock
	dateBlock = []
	if (!isLoading && allTeamGamesAndEvents.length === 0) {
		return (
			<div style={{ overflowX: 'hidden' }}>
				<React.Fragment>
					<div className='Roster' style={{ marginTop: '6rem' }}>
						<div>
							<img src={teamLogo} className='teamLogo' />
						</div>
						<h2>Team Schedule</h2>
						<div style={{ textAlign: 'center' }}>
							{!isLoading && loadedTeams && (
								<form className='form-control-lg'>
									<select
										className='my-1 mr-sm-2 selectLeagueDropdown'
										onChange={handleChange}
										style={{
											background: 'white',
											borderWidth: '3px',
											width: '30vh',
											fontSize: 'medium',
											fontFamily: 'Montserrat, Work Sans, sans-serif',
										}}
									>
										<option defaultValue>Select a Team</option>
										{teamArray.map((team, i) => (
											<option value={i} key={i}>
												{team.toUpperCase()}
											</option>
										))}
									</select>
								</form>
							)}
						</div>
					</div>
					<div style={{ marginTop: `${rem}` }}>
						<Footer />
					</div>
				</React.Fragment>
			</div>
		)
	}
	if (!isLoading && allTeamGamesAndEvents.length !== 0) {
		dateBlock.push(
			<div style={{ marginTop: '6rem' }} className='Schedule'>
				<div>
					<img src={teamLogo} className='teamLogo' />
				</div>
				<div style={{ textAlign: 'center' }}>
					{!isLoading && loadedTeams && (
						<form className='form-control-lg'>
							<select
								className='my-1 mr-sm-2 selectLeagueDropdown'
								onChange={handleChange}
								style={{
									background: 'white',
									borderWidth: '3px',
									width: '30vh',
									fontSize: 'medium',
									fontFamily: 'Montserrat, Work Sans, sans-serif',
								}}
							>
								<option defaultValue>Select a Team</option>
								{teamArray.map((team, i) => (
									<option value={i} key={i}>
										{team.toUpperCase()}
									</option>
								))}
							</select>
						</form>
					)}
				</div>
				<h2>
					Record: {wins} - {losses} - {ties + shootoutLosses + overtimeLosses}
				</h2>
				<h3 className='h3Center'>{teamName + ' - ' + year}</h3>
				<p>(Click on the final score of a game to see a Game Summary)</p>
				<table id='teamScheduleDisplay'>
					<thead>
						<tr>
							<th>Date</th>
							<th>Time</th>
							<th>Status</th>
							<th>Score</th>
							<th>Opponent</th>
							<th></th>
							<th>Venue</th>
						</tr>
					</thead>
					<tbody>
						{allTeamGamesAndEvents.map((game, key) => {
							// if (game.venueName === venue) {
							return (
								<tr key={key}>
									<td>
										{game.dayOfWeek}
										{', '}
										{game.date.substr(0, 2) === '01' &&
											'Jan. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '02' &&
											'Feb. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '03' &&
											'Mar. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '04' &&
											'Apr. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '05' &&
											'May ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '06' &&
											'June ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '07' &&
											'July ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '08' &&
											'Aug. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '09' &&
											'Sept. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '10' &&
											'Oct. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '11' &&
											'Nov. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '12' &&
											'Dec. ' + game.date.substr(3, 2)}
									</td>
									<td>
										{game.time.substr(0, 2) === '00' &&
											'12:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '01' &&
											'1:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '02' &&
											'2:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '03' &&
											'3:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '04' &&
											'4:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '05' &&
											'5:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '06' &&
											'6:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '07' &&
											'7:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '08' &&
											'8:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '09' &&
											'9:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '10' &&
											'10:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '11' &&
											'11:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '12' &&
											'12:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '13' &&
											'1:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '14' &&
											'2:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '15' &&
											'3:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '16' &&
											'4:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '17' &&
											'5:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '18' &&
											'6:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '19' &&
											'7:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '20' &&
											'8:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '21' &&
											'9:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '22' &&
											'10:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '23' &&
											'11:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '24' &&
											'12:' + game.time.substr(3, 2) + ' am'}
									</td>
									<td>
										{(game.status && game.status !== 'TBP' && (
											<Link
												style={{
													textDecoration: 'none',
													fontWeight: 600,
													color: 'black',
												}}
												to={`/gameSummary/${game._id}`}
											>
												{game.status}
											</Link>
										)) ||
											'TBP'}
									</td>
									{!game.score && <td></td>}
									{game.score && !game.winner && !game.loser && (
										<td style={{ color: 'black', fontWeight: 'bold' }}>
											<Link
												style={{
													textDecoration: 'none',
													fontWeight: 600,
													color: 'black',
												}}
												to={`/gameSummary/${game._id}`}
											>
												TIED {game.score}
											</Link>
										</td>
									)}
									{game.winner === game.opponent && !game.eventName && (
										<td style={{ color: 'red', fontWeight: 'bold' }}>
											<Link
												style={{
													textDecoration: 'none',
													fontWeight: 600,
													color: 'red',
												}}
												to={`/gameSummary/${game._id}`}
											>
												LOST {game.score}
											</Link>
										</td>
									)}
									{game.winner === teamName && (
										<td style={{ color: 'green', fontWeight: 'bold' }}>
											<Link
												style={{
													textDecoration: 'none',
													fontWeight: 600,
													color: 'green',
												}}
												to={`/gameSummary/${game._id}`}
											>
												WON {game.score}
											</Link>
										</td>
									)}
									{game.opponent && <td>{game.opponent}</td>}
									{game.eventName && (
										<td
											style={{
												fontWeight: 'bold',
												backgroundColor: 'pink',
											}}
										>
											{game.eventName}
										</td>
									)}
									{!game.playoff && !game.championship && <td></td>}
									{game.playoff && (
										<td style={{ color: 'red', fontWeight: 'bold' }}>
											PLAYOFF GAME
										</td>
									)}

									{game.championship && (
										<td style={{ color: 'red', fontWeight: 'bold' }}>
											CHAMPIONSHIP GAME
										</td>
									)}
									<td>{game.venueName}</td>
								</tr>
							)
							//}
						})}
					</tbody>
				</table>
			</div>
		)
		// }
		//)
	}

	return (
		<div style={{ overflowX: 'hidden' }}>
			<React.Fragment>
				<SchedulesHomeNavigation teamId={teamId} />
				{dateBlock && dateBlock}
				<Footer />
			</React.Fragment>
		</div>
	)
}

export default TeamSchedule
