import React, { useEffect, useState } from 'react'
import './AdminPlayersList.css'
import { NavLink } from 'react-router-dom'
//import Button from '../../shared/components/UIElements/Button'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import AdminNavigation from './AdminNavigation'
import { useParams } from 'react-router-dom'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import Modal from '../../shared/components/UIElements/Modal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import { useHttpClient } from '../../shared/hooks/http-hook'

const AdminPlayersOnTeamList = () => {
	const { sendRequest, clearError } = useHttpClient()
	const [isLoading, setIsLoading] = useState(false)
	const [rosterPlayerId, setRosterPlayerId] = useState('')
	const [playerToDelete, setPlayerToDelete] = useState('')
	const [error, setError] = useState()
	const [loadedPlayers, setLoadedPlayers] = useState()
	const [isCurrent, setIsCurrent] = useState()
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [playerDeleted, setPlayerDeleted] = useState()
	const year = useParams().year
	const teamName = useParams().teamName

	//let navigate = useNavigate()

	//This fetchs a list of all the players on this team
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			setPlayerDeleted(false)

			try {
				const response = await fetch(
					// `${process.env.REACT_APP_BACKEND_URL}/admin/${leagueName}/${session}/${year}/${teamName}/players`
					`${process.env.REACT_APP_BACKEND_URL}/admin/${teamName}/${year}/roster`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}
				setLoadedPlayers(responseData.listOfPlayers)
				setIsCurrent(responseData.isCurrent)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [teamName, year, setLoadedPlayers, playerDeleted])

	const errorHandler = () => {
		setError(null)
	}

	//So...we have out list of playerId's from the database.
	//For each playerId, we want to get their name data
	if (loadedPlayers && !isLoading) {
	}
	//console.log('isCurrent: ' + isCurrent)

	const showDeleteWarningHandler = (e) => {
		console.log('e: ' + e.target.id)
		const value = e.target.id
		console.log(
			'Player you wish to delete: ' + JSON.stringify(loadedPlayers[value])
		)
		setPlayerToDelete(
			loadedPlayers[value].firstName + ' ' + loadedPlayers[value].lastName
		)
		//rosterPlayerId = loadedPlayers[value]._id
		setRosterPlayerId(loadedPlayers[value]._id)
		setShowConfirmModal(true)
	}

	const cancelDeleteHandler = () => {
		setShowConfirmModal(false)
	}

	//console.log('roster player right here: ' + rosterPlayerId)
	const confirmDeleteHandler = async () => {
		setShowConfirmModal(false)
		//console.log('rosterPlayerId: ' + rosterPlayerId)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${rosterPlayerId}/removePlayer`,
				'DELETE'
			)
		} catch (err) {}
		setPlayerDeleted(true)
		console.log('DELETING...')
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			<Modal
				show={showConfirmModal}
				onCancel={cancelDeleteHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to remove this player:
						</p>
						<h1>{playerToDelete}</h1>
						<br></br>
						<button
							className='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelDeleteHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							style={{ color: 'red' }}
							danger
							onClick={confirmDeleteHandler}
						>
							DELETE PLAYER
						</button>
					</React.Fragment>
				}
			></Modal>
			{!isLoading && loadedPlayers && (
				<div className='Players'>
					<AdminNavigation />
					<h1>{teamName}</h1>
					<h2>{year}</h2>
					<table id='players'>
						<thead>
							<tr>
								<th>Player Name</th>
								<th>Number</th>
								<th></th>
								<th></th>
								{isCurrent && <th></th>}
							</tr>
						</thead>
						<tbody>
							{loadedPlayers.map((player, key) => {
								//loadedPlayers is a list of rosterPlayer schemas
								//rosterPlayerId = player._id
								//console.log('key: ' + key)
								return (
									<tr key={key}>
										<td id={key}>
											{player.firstName} {player.middleInitial}{' '}
											{player.lastName} {player.isCaptain && '(Captain)'}{' '}
											{player.isGoalie && '(Goalie)'}
										</td>
										<td>{player.number}</td>
										<td>
											<NavLink
												className='navlinks'
												// to={`/admin/${leagueName}/${session}/${year}/${teamName}/${player.playerId}/changeNumber`}
												to={`/admin/${teamName}/${year}/${player.playerId}/changeNumber`}
												exact
											>
												Edit
											</NavLink>
										</td>
										<td>
											<NavLink
												className='navlinks'
												to={`/admin/${player._id}/playerPhoto`}
												exact
											>
												Add Photo
											</NavLink>
										</td>
										{isCurrent && (
											<td>
												<button
													className='buttonButton'
													style={{ color: 'red' }}
													id={key}
													onClick={(e) => showDeleteWarningHandler(e)}
												>
													Remove Player
												</button>
											</td>
										)}
									</tr>
								)
							})}
							{isCurrent && <tr className='add'></tr>}
						</tbody>
						{isCurrent && (
							<AdminButton
								className='add'
								type='button'
								style={{ width: '150%' }}
								//to={`/admin/${leagueName}/${teamName}/${year}/addPlayers`}
								to={`/admin/${teamName}/${year}/addPlayers`}
							>
								Add Player(s)
							</AdminButton>
						)}
					</table>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminPlayersOnTeamList
