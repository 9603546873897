//Create a roster for each team.
//Even while not logged in, you can see a list of players within the chosen team.
//This here is a page that OUTPUTS that list.
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import './../../../players/components/Players.css'
import ErrorModal from '../UIElements/ErrorModal'
import LoadingSpinner from '../UIElements/LoadingSpinner'
import Footer from '../UIElements/Footer'
import FAQHomeNavigation from '../../../players/components/FAQHomeNavigation'
import FAQ from './FAQ'
import { useHttpClient } from '../../hooks/http-hook'

const FAQs = () => {
	console.log('inside FAQs')
	const { sendRequest, clearError } = useHttpClient()
	const [error, setError] = useState()
	const [loadedFaqs, setLoadedFaqs] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [faqs, setFaqs] = useState([])

	//
	//
	//First, lets tap into the following route on the backend to get all faqs:
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + `/admin/faqs`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setFaqs(responseData.allFaqs)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [])
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	loadedFaqs && console.log('loadedFaqs: ' + JSON.stringify(faqs))
	//
	//
	//******************************************************** */
	//
	//    FAQ Accordion stuff
	//
	//******************************************************** */
	const toggleFAQ = (index) => {
		setFaqs(
			faqs.map((faq, i) => {
				if (i === index) {
					faq.open = !faq.open
				} else {
					faq.open = false
				}
				return faq
			})
		)
	}
	//
	//
	//
	//
	//
	//
	//
	return (
		<div style={{ overflowX: 'hidden' }}>
			<React.Fragment>
				<FAQHomeNavigation />
				<ErrorModal error={error} onClear={errorHandler} />
				{isLoading && (
					<div className='center' style={{ marginTop: '10rem' }}>
						<LoadingSpinner />
					</div>
				)}
				{/* //
			//
			//   This is for if there's only previous players.  No games
			//
			// */}
				{!isLoading && (
					<React.Fragment>
						<div className='row' style={{ marginTop: '6rem' }}>
							<div className='col text-center mb-3'>
								<h1
									className='display-4 lead headingText'
									style={{
										fontWeight: '600',
										color: '#410009',
										textShadow: '2px 2px 2px #fee09a',
									}}
								>
									Frequently Asked Questions
								</h1>
							</div>
						</div>
						<div className='row'>
							<div className='faqs'>
								{faqs.map((faq, index) => (
									<FAQ
										faq={faq}
										index={index}
										key={index}
										toggleFAQ={toggleFAQ}
									/>
								))}
							</div>
						</div>
						<div style={{ marginTop: '2rem' }}>
							<Footer />
						</div>
					</React.Fragment>
				)}
			</React.Fragment>
		</div>
	)
}

export default FAQs
